import { displayIntervenantResource, displayPrescriptionAddressResource } from "@ui/helpers/_functions/displaySpecificResource";
import calculateTotalPatientsCount from "@ui/helpers/_functions/calculateTotalPatientsCount";
import { PATIENT, PRESCRIBER, COMMUNITY } from "@common/constants/entityTypes";
import {
  fetchPoles,
  fetchRoles,
  fetchTaxonomies,
  fetchInterventions,
  fetchInterventionTypes,
  fetchInterventionStatuses,
  fetchTitles,
  fetchChannels,
  fetchAlertTemplates,
  fetchForfaits,
  fetchFileTypes,
  fetchDeviceMetrics,
  fetchDeviceSettings,
  fetchFile,
  fetchProducts,
  fetchIntervenants,
  fetchEntityInterventionStatuses,
  fetchProductsReminders,
  fetchContacts,
  fetchUsers,
  searchProducts,
  fetchStockContentWithoutDetails
} from "@/modules/common/_api";
import { formatPoles, formatRoles, formatTaxonomies, formatChannels } from "@/modules/common/_functions";

import bindFetchWithActions from "./bindFetchWithActions";

const entitlements = require("@common/constants/entitlements");
const agaProducts = require("@common/constants/agaProducts");

const actions = {
  /**
   *
   * @param {Object} context
   * @param {Array} resources Array of resources needed (ex: ["poles", "roles"])
   * @returns
   */
  fetchCommon(context, resources) {
    return new Promise((resolve, reject) => {
      if (!resources || !resources.length) {
        resolve();
        return;
      }

      const notInCacheResources = resources.filter(resource => !context.state.taxonomies[resource]);

      const taxonomyResources = notInCacheResources.filter(resource => bindFetchWithActions[resource] === "fetchTaxonomies");

      const othersResources = notInCacheResources.filter(resource => bindFetchWithActions[resource] !== "fetchTaxonomies");

      const fetches = [];

      if (taxonomyResources.length) {
        fetches.push(context.dispatch("fetchTaxonomies", taxonomyResources));
      }
      if (othersResources.length) {
        othersResources.forEach(resource => {
          fetches.push(context.dispatch(bindFetchWithActions[resource]));
        });
      }

      Promise.all(fetches)
        .then(() => resolve())
        .catch(err => reject(err));
    });
  },
  fetchPoles(context) {
    return new Promise((resolve, reject) => {
      fetchPoles()
        .then(({ data }) => {
          const poles = formatPoles(data.body);
          context.commit("SET_TAXONOMIES", { poles });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchRoles(context) {
    return new Promise((resolve, reject) => {
      fetchRoles()
        .then(({ data }) => {
          context.commit("SET_TAXONOMIES", { roles: formatRoles(data.body) });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTaxonomies(context, name) {
    return new Promise((resolve, reject) => {
      fetchTaxonomies(name)
        .then(({ data }) => {
          context.commit("SET_TAXONOMIES", formatTaxonomies(data.body));
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchInterventionTypes(context) {
    return new Promise((resolve, reject) => {
      fetchInterventionTypes()
        .then(({ data }) => {
          const interventionTypes = data.body.map(type => ({
            label: type.label,
            value: type._id,
            divisionId: type.divisionId,
            canBeUsedInCustomProtocols: type.canBeUsedInCustomProtocols,
            noPlanningDisplay: type.noPlanningDisplay || false
          }));
          const interventionTypesLimited = data.body
            .filter(type => !type.auto && !type.disabled)
            .map(type => ({ label: type.label, value: type._id, divisionId: type.divisionId }));
          context.commit("SET_TAXONOMIES", { interventionTypes, interventionTypesLimited });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTitles(context) {
    return new Promise((resolve, reject) => {
      fetchTitles()
        .then(({ data }) => {
          const titles = data.body.map(title => ({ label: title.label, value: title._id, entities: title.entities }));
          const patientsTitles = titles.filter(title => title.entities.includes(PATIENT));
          const prescribersTitles = titles.filter(title => title.entities.includes(PRESCRIBER));
          const communityTitles = titles.filter(title => title.entities.includes(COMMUNITY));

          context.commit("SET_TAXONOMIES", { titles, patientsTitles, prescribersTitles, communityTitles });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchInterventionStatuses(context) {
    return new Promise((resolve, reject) => {
      fetchInterventionStatuses()
        .then(({ data }) => {
          const interventionStatuses = data.body.map(status => ({
            label: status.label,
            value: status._id,
            divisionId: status.divisionId,
            special: status.special
          }));

          context.commit("SET_TAXONOMIES", { interventionStatuses });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchChannels(context) {
    return new Promise((resolve, reject) => {
      fetchChannels()
        .then(({ data }) => {
          context.commit("SET_TAXONOMIES", {
            channels: data.body ? formatChannels(data.body) : []
          });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFileTypes(context) {
    return new Promise((resolve, reject) => {
      fetchFileTypes()
        .then(({ data }) => {
          context.commit("SET_TAXONOMIES", {
            fileTypes: data.body.map(item => ({ label: item.label, value: item._id, divisionIds: item.divisionIds, hidden: item.auto }))
          });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAlertTemplates(context) {
    return new Promise((resolve, reject) => {
      fetchAlertTemplates({ query: { activeStatus: ["active"] } })
        .then(res => {
          const { results } = res.data.body;
          /* Needs to cut in 3 differents arrays to sort in term of monitoring type and alphabatically because 
          of strange behaviour of sort with multiple keys */
          const generalTemplates = results.filter(item => item.monitoringTypeId === "general");
          generalTemplates.sort((current, next) => (current.label < next.label ? -1 : 1));
          const prescriberTemplates = results.filter(item => item.monitoringTypeId === "prescriber");
          prescriberTemplates.sort((current, next) => (current.label < next.label ? -1 : 1));
          const patientTemplates = results.filter(item => item.monitoringTypeId === "patient");
          patientTemplates.sort((current, next) => (current.label < next.label ? -1 : 1));
          const alertTemplates = [...generalTemplates, ...prescriberTemplates, ...patientTemplates].map(template => ({
            label: template.label,
            value: template._id
          }));
          context.commit("SET_TAXONOMIES", { alertTemplates });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchForfaits(context) {
    return new Promise((resolve, reject) => {
      fetchForfaits()
        .then(res => {
          const forfaits =
            res.data && res.data.body
              ? res.data.body.map(forfait => ({
                  label: forfait.label,
                  value: forfait._id,
                  treatments: forfait.treatments,
                  lppCode: forfait.lppCode,
                  divisionId: forfait.divisionId
                }))
              : [];
          context.commit("SET_TAXONOMIES", { forfaits });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchInterventions(context, params) {
    return new Promise((resolve, reject) => {
      fetchInterventions(params)
        .then(res => {
          context.commit("SET_INTERVENTIONS", res);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchDeviceSettings(context) {
    return new Promise((resolve, reject) => {
      fetchDeviceSettings()
        .then(res => {
          const deviceSettings = res.data.body ? res.data.body.map(setting => ({ ...setting, value: setting._id })) : [];
          context.commit("SET_TAXONOMIES", { deviceSettings });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchDeviceMetrics(context) {
    return new Promise((resolve, reject) => {
      fetchDeviceMetrics()
        .then(res => {
          const deviceMetrics = res.data.body ? res.data.body.map(metric => ({ ...metric, value: metric._id, treatmentTypeIds: metric.types })) : [];
          context.commit("SET_TAXONOMIES", { deviceMetrics });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAgaProducts(context) {
    return new Promise((resolve, reject) => {
      fetchProducts({ query: { productIds: agaProducts } })
        .then(res => {
          const result =
            res.data.body && res.data.body
              ? res.data.body.results.map(product => ({ value: product._id, label: `${product._id} - ${product.alternativeLabel || product.label}` }))
              : [];
          context.commit("SET_TAXONOMIES", { agaProducts: result });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPumps(context) {
    return new Promise((resolve, reject) => {
      searchProducts({
        query: { divisions: ["D"], productSubtypeIds: ["pump"], active: true },
        skip: 0,
        limit: 50,
        source: "local"
      })
        .then(res => {
          const result =
            res.data.body && res.data.body
              ? res.data.body.map(product => ({ value: product._id, label: product.alternativeLabel || product.label }))
              : [];
          context.commit("SET_TAXONOMIES", { pumps: result });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchIntervenants(context) {
    return new Promise((resolve, reject) => {
      const { user } = context.rootState.login;
      const poleIds = user && user.poleIds && user.poleIds.length ? user.poleIds : [];
      const query = {
        poleIds: (user.isIntervenant && !user.isAstreinte) || user.isSecretairePlanification ? poleIds : null
      };
      fetchIntervenants({ query, limit: 10000 })
        .then(res => {
          const { results } = res.data.body;
          const intervenants = results
            ? results.filter(item => item.email).map(item => ({ ...item, value: item._id, label: displayIntervenantResource(item) }))
            : [];

          const intervenantIdec = results
            ? results
                .filter(item => item.email && item.sapEmployeeId)
                .map(item => ({ ...item, value: item.sapEmployeeId, label: displayIntervenantResource(item) }))
            : [];
          const intervenantZones = results
            ? results
                .filter(item => item.email && item.zoneId)
                .map(item => ({ ...item, value: item.zoneId, label: displayIntervenantResource(item, "zoneId") }))
            : [];
          const intervenantExternalIds = results
            ? results
                .filter(item => item.email && item.externalId)
                .map(item => ({ ...item, value: item.externalId, label: displayIntervenantResource(item, "externalId") }))
            : [];
          const intervenantThatCanRealiseInterventions = results
            ? results
                .filter(item => item.email && item.entitlementIds.includes(entitlements.realiseInterventions))
                .map(item => ({ ...item, value: item._id, label: displayIntervenantResource(item, "externalId") }))
            : [];
          intervenants.sort((a, b) => (a.label < b.label ? -1 : 1));
          intervenantIdec.sort((a, b) => (a.label < b.label ? -1 : 1));
          intervenantZones.sort((a, b) => (a.label < b.label ? -1 : 1));
          intervenantExternalIds.sort((a, b) => (a.label < b.label ? -1 : 1));
          intervenantThatCanRealiseInterventions.sort((a, b) => (a.label < b.label ? -1 : 1));
          context.commit("SET_TAXONOMIES", {
            intervenants,
            intervenantIdec,
            intervenantZones,
            intervenantExternalIds,
            intervenantThatCanRealiseInterventions
          });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchUsersThatCanBeLinkedToIntervention(context, query = {}) {
    return new Promise((resolve, reject) => {
      fetchUsers({ query: { ...query, active: true, entitlementIds: [entitlements.realiseInterventions] }, limit: 10000 })
        .then(response => {
          const usersThatCaneLinkedToIntervention = response?.data?.body?.results.map(item => {
            return { ...item, value: item._id, label: displayIntervenantResource(item, "searchString") };
          });
          resolve(usersThatCaneLinkedToIntervention);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchUserPrescriptionAddresses(context) {
    return new Promise(resolve => {
      const userPrescriptionAddresses =
        context.rootState.login.user && context.rootState.login.user.prescriptionAddresses
          ? context.rootState.login.user.prescriptionAddresses
              .filter(item => calculateTotalPatientsCount(item.patientsCount))
              .map(item => ({
                ...item,
                value: item._id,
                label: displayPrescriptionAddressResource(item)
              }))
          : [];
      context.commit("SET_TAXONOMIES", { userPrescriptionAddresses });
      resolve();
    });
  },
  fetchEntityInterventionStatuses(context) {
    return new Promise((resolve, reject) => {
      fetchEntityInterventionStatuses()
        .then(({ data }) => {
          const entityInterventionStatuses = data.body.map(status => ({ label: status.label, value: status._id, divisionId: status.divisionId }));
          const entityInterventionStatusesLimited = data.body
            .filter(status => !status.special)
            .map(status => ({ label: status.label, value: status._id, divisionId: status.divisionId }));
          context.commit("SET_TAXONOMIES", { entityInterventionStatuses, entityInterventionStatusesLimited });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchProductsReminders(context, params) {
    return new Promise((resolve, reject) => {
      fetchProductsReminders(params)
        .then(res => {
          context.commit("SET_PRODUCTS_REMINDERS", res?.data?.body?.results || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchContacts(context, params) {
    return new Promise((resolve, reject) => {
      fetchContacts(params)
        .then(res => {
          resolve(res?.data?.body?.results || []);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchStockContentWithoutDetails(context, { stockId, ...params }) {
    return new Promise((resolve, reject) => {
      fetchStockContentWithoutDetails(stockId, params)
        .then(res => {
          resolve(res?.data?.body || []);
        })
        .catch(err => {
          return reject(err);
        });
    });
  }
};

export default { actions };
