import deliveryStatuses from "@common/constants/deliveryStatuses";
import { fetchCommunityStockContent } from "@/modules/common/_api";
import formatCompleteStock from "@/helpers/_functions/stock/formatCompleteStock";

import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import defaultCautionMessages from "@/helpers/_constants/defaultCautionMessages";

const state = {
  stock: null,
  loading: {
    fetchStock: false
  }
};

const getters = {
  stock: state => state.stock,
  stockMachines: state => state.stock?.filter(item => item.product?.typeId === "machine"),
  stockConsommables: state => state.stock?.filter(item => item.product?.typeId !== "machine")
};

const mutations = {
  SET_ENTITY_STOCK: (state, stock) => {
    state.stock = stock;
  }
};

const actions = {
  fetchEntityStock(context, forceRequest = false) {
    const { community, stock } = context.state;

    if (!community.stockId) {
      return;
    }

    if (stock && !forceRequest) {
      return;
    }

    context.commit("UPDATE_LOADING", { fetchStock: true });
    context.dispatch("updateFeedbackMessages", { caution: defaultCautionMessages.fetchingStock });

    fetchCommunityStockContent(community.stockId, { maintenanceInfos: true })
      .then(res => {
        const formattedStock = formatCompleteStock(res?.data?.body || []).map(item => ({ ...item, statusId: deliveryStatuses.delivered }));

        context.commit("SET_ENTITY_STOCK", formattedStock);
      })
      .catch(() => {
        context.dispatch("updateFeedbackMessages", { errorFetchStock: defaultErrorMessages.search });
      })
      .finally(() => {
        context.dispatch("updateFeedbackMessages", { caution: null });
        context.commit("UPDATE_LOADING", { fetchStock: false });
      });
  }
};

export default { state, getters, mutations, actions };
