// noCrudContact sert à distinguer les types de contacts qui ne font pas partie du CRUD des contacts.
// Cette notion est pour l'instant uniquement dans bastide-diabete

const contactTypesRespiratory = [
  {
    value: "mainTech",
    label: "Intervenant principal",
    noCrudContact: true
  },
  {
    value: "pole",
    label: "Pôle",
    hasLabel: true,
    noCrudContact: true
  },
  {
    value: "prescriptionAddress",
    label: "Adresse de prescription",
    noCrudContact: true
  },
  {
    value: "secondaryPrescriptionAddress",
    label: "Adresse de prescription confrère",
    noCrudContact: true
  },
  {
    value: "otherPrescriptionAddress",
    label: "Autre adresses de prescription",
    noCrudContact: true
  }
];

const contactTypesDiabete = [
  {
    value: "cityPharmacy",
    label: "Pharmacie de ville",
    hasLabel: true
  },
  {
    value: "laboratory",
    label: "Laboratoire",
    hasLabel: true
  },
  {
    value: "prescriber",
    label: "Prescripteur"
  },
  {
    value: "mainTech",
    label: "Intervenant principal",
    noCrudContact: true
  },
  {
    value: "idel",
    label: "IDEL",
    hasTraining: true
  },
  {
    value: "personalPhysician",
    label: "Médecin traitant"
  },
  {
    value: "healthExecutive",
    label: "Cadre de santé"
  },
  {
    value: "serviceDietician",
    label: "Diététicien du service"
  },
  {
    value: "establishment",
    label: "Établissement",
    hasLabel: true
  },
  {
    value: "service",
    label: "Service",
    hasLabel: true
  },
  {
    value: "pole",
    label: "Pôle",
    hasLabel: true,
    noCrudContact: true
  },
  {
    value: "other",
    label: "Autre",
    hasTraining: true
  },
  {
    value: "prescriptionAddress",
    label: "Adresse de prescription",
    noCrudContact: true
  },
  {
    value: "secondaryPrescriptionAddress",
    label: "Adresse de prescription confrère",
    noCrudContact: true
  },
  {
    value: "otherPrescriptionAddress",
    label: "Autre adresses de prescription",
    noCrudContact: true
  }
];

const contactTypesRespiratoryMedpro = [
  {
    value: "pole",
    label: "Pôle",
    hasLabel: true,
    noCrudContact: true
  },
  {
    value: "mainTech",
    label: "Intervenant principal",
    noCrudContact: true
  },
  {
    value: "prescriptionAddress",
    label: "Prescription address",
    noCrudContact: true
  },
  {
    value: "secondaryPrescriptionAddress",
    label: "Adresse de prescription confrère",
    noCrudContact: true
  },
  {
    value: "referralPrescriber",
    label: "Referral prescriber",
    noCrudContact: true
  },
  {
    value: "familyPrescriber",
    label: "Family prescriber",
    noCrudContact: true
  },
  {
    value: "interpretingPhysician",
    label: "Interpreting prescriber",
    noCrudContact: true
  },
  {
    value: "otherPrescriptionAddress",
    label: "Other prescription address",
    noCrudContact: true
  }
];

module.exports = { contactTypesRespiratory, contactTypesDiabete, contactTypesRespiratoryMedpro };
