/* eslint-disable import/no-cycle */
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import moment from "moment";

import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";

import {
  fetchPrescriptionAddresses,
  fetchPatient,
  fetchUser,
  fetchInterventions,
  fetchForfaits,
  fetchPrescribers,
  fetchCommunity
} from "@/modules/common/_api";
import { checkPlanningSlotAvailability } from "@/modules/users/view/_api";
import * as api from "../_api";
import _deliveries from "./deliveries";
import _signature from "./signature";
import _anomaly from "./anomaly";
import _planification from "./planification";
import _editByPdf from "./editByPdf";
import _interventionQuestionnaire from "./intervention-questionnaire";

const fileTypes = require("@common/constants/fileTypes");
const interventionTypes = require("@common/constants/interventionTypes");
const getPossibleInterventionTypedLocations = require("@/helpers/_functions/getPossibleInterventionTypedLocations");
const getInterventionLocationOptions = require("@/helpers/_functions/getInterventionLocationOptions");

/* eslint-disable no-shadow */

/* Factory function to create state on demand, so we can use it on destroy to reset clean state */
const getDefaultState = () => {
  return {
    ..._deliveries.state,
    ..._anomaly.state,
    ..._planification.state,
    ..._interventionQuestionnaire.state,
    intervention: null,
    previousIntervention: null,
    rawIntervention: null,
    patient: null,
    prescriptionAddress: null,
    technician: null,
    community: null,
    locations: [],
    formattedLocations: [],
    taxonomies: {
      ..._deliveries.state.taxonomies
    },
    feedbackMessages: {
      ..._deliveries.state.feedbackMessages,
      ..._editByPdf.state.feedbackMessages,
      success: null,
      error: null,
      errorForm: null
    },
    loading: {
      ..._deliveries.state.loading,
      intervention: true,
      patient: true,
      prescriptionAddress: true,
      deliveries: true,
      preUseChecklist: false,
      submit: false,
      technician: false,
      reportGeneration: false
    },
    nextAction: null,
    nextRoute: null
  };
};

const state = getDefaultState();

const getters = {
  ..._deliveries.getters,
  ..._anomaly.getters,
  ..._planification.getters,
  ..._interventionQuestionnaire.getters,
  intervention: state => state.intervention,
  interventions: state => state.interventions,
  previousIntervention: state => state.previousIntervention,
  patient: state => state.patient,
  formattedLocations: state => state.formattedLocations,
  prescriptionAddress: state => state.prescriptionAddress,
  technician: state => state.technician,
  deliveries: state => state.deliveries,
  taxonomies: state => state.taxonomies,
  community: state => state.community,
  feedbackMessages: state => state.feedbackMessages,
  loading: state => state.loading,
  nextAction: state => state.nextAction,
  nextRoute: state => state.nextRoute
};

const mutations = {
  ..._deliveries.mutations,
  ..._anomaly.mutations,
  ..._planification.mutations,
  ..._interventionQuestionnaire.mutations,
  SET_INTERVENTION: (state, intervention) => {
    state.intervention = intervention;
  },
  SET_INTERVENTIONS: (state, interventions) => {
    state.interventions = interventions;
  },
  SET_RAW_INTERVENTION: (state, rawIntervention) => {
    state.rawIntervention = rawIntervention;
  },
  SET_PREVIOUS_INTERVENTION: (state, previousIntervention) => {
    state.previousIntervention = previousIntervention;
  },
  SET_PATIENT: (state, patient) => {
    state.patient = patient;
  },
  SET_PRESCRIPTION_ADDRESS: (state, prescriptionAddress) => {
    state.prescriptionAddress = prescriptionAddress;
  },
  SET_TECHNICIAN: (state, technician) => {
    state.technician = technician;
  },
  SET_TAXONOMIES: (state, taxonomies) => {
    state.taxonomies = taxonomies;
  },
  SET_COMMUNITY: (state, community) => {
    state.community = community;
  },
  SET_LOCATIONS: (state, locations) => {
    state.locations = locations;
  },
  SET_FORMATTED_LOCATIONS: (state, locations) => {
    state.formattedLocations = locations;
  },
  SET_FEEDBACK_MESSAGES: (state, messages) => {
    state.feedbackMessages = messages;
  },
  SET_FEEDBACK_MESSAGES_ERROR: (state, messages) => {
    state.feedbackMessages.error = messages;
  },
  SET_FEEDBACK_MESSAGES_SUCCESS: (state, messages) => {
    state.feedbackMessages.success = messages;
  },
  SET_NEXT_ACTION: (state, nextAction) => {
    state.nextAction = nextAction;
  },
  SET_NEXT_ROUTE: (state, nextRoute) => {
    state.nextRoute = nextRoute;
  },
  UPDATE_LOADING: (state, loadingItems) => {
    state.loading = { ...state.loading, ...loadingItems };
  },
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  ..._deliveries.actions,
  ..._signature.actions,
  ..._anomaly.actions,
  ..._planification.actions,
  ..._editByPdf.actions,
  ..._interventionQuestionnaire.actions,
  fetchInterventionView(context, interventionId) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { intervention: true });
      /* First we fetch the intervention and taxonomies */
      Promise.all([
        context.dispatch("fetchIntervention", interventionId),
        context.dispatch(
          "common/fetchCommon",
          [
            "fileTypes",
            "pathologiesRespi",
            "prescriberAdministrativeProtocols",
            "prescriberTechnicalProtocolsValues",
            "renewalInformationChoices",
            "interventionTypes",
            "interventionStatuses",
            "deviceModes",
            "deviceSettings",
            "deviceMetrics",
            "forfaits",
            "recuperationMotifs",
            "riskAnalysisSections",
            "riskAnalysisTypes",
            "riskAnalysisSubTypes",
            "poles",
            "sizeUnits",
            "weightUnits",
            "countries",
            "telesuiviManufacturers"
          ],
          { root: true }
        )
      ])
        .then(() => {
          const { patientId } = context.state.intervention;
          Promise.resolve(context.dispatch("fetchPatient", patientId))
            .then(() => {
              /* We now have access to patientId and prescriptionAddressId related to this intervention so we fetch them */
              const { prescriptionAddressId, intervenantId, contractId } = context.state.intervention;

              const interventionFetches = [
                context.dispatch("fetchPreviousIntervention"),
                context.dispatch("fetchPrescriptionAddress", prescriptionAddressId),
                context.dispatch("fetchDeliveries", { query: { contractId, patientId, active: true } })
              ];

              if (intervenantId) {
                interventionFetches.push(context.dispatch("fetchIntervenant", intervenantId));
              }

              Promise.all(interventionFetches)
                .then(() => {
                  /* Once we have access to patient, we can retrieve his contacts and secondary prescription addresses */
                  const interventionContract = context.state.patient.contracts?.find(
                    contract => contract.id === context.state.intervention.contractId
                  );

                  if (!interventionContract) {
                    context.commit("UPDATE_LOADING", { intervention: false });
                    return resolve();
                  }

                  const contractFetches = [];

                  if (interventionContract.forfaitIds && interventionContract.forfaitIds.length) {
                    contractFetches.push(context.dispatch("fetchInterventionForfait", interventionContract.forfaitIds));
                  }
                  if (interventionContract.otherPrescriptionAddressIds?.length || interventionContract.secondaryPrescriptionAddressId) {
                    contractFetches.push(context.dispatch("fetchConfrerePrescriber"));
                  }
                  if (interventionContract.prescriptionTypeId === "CO") {
                    contractFetches.push(context.dispatch("fetchPatientCommunity"));
                  }

                  /* If the current intervention is R, we need to retrieve the last compteur value to compute observance */
                  contractFetches.push(context.dispatch("getLastCompteur"));

                  Promise.all(contractFetches)
                    .then(() => {
                      resolve();
                    })
                    .catch(error => {
                      context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.fetch });
                      reject(error);
                    })
                    .finally(() => {
                      context.commit("UPDATE_LOADING", { intervention: false });
                    });
                })
                .catch(error => {
                  context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.fetch });
                  reject(error);
                });
            })
            .catch(error => {
              context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.fetch });
              reject(error);
            });
        })
        .catch(error => {
          context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.fetch });
          reject(error);
        });
    });
  },
  fetchIntervention(context, id) {
    return new Promise((resolve, reject) => {
      api
        .fetchIntervention(id)
        .then(res => {
          const intervention = res.data.body;
          const { prescriber, ...rawIntervention } = res.data.body;
          context.commit("SET_RAW_INTERVENTION", rawIntervention);
          context.commit("SET_INTERVENTION", intervention);

          const stockId = intervention?.stockId;

          if (stockId) {
            context.dispatch("common/setSearchInStocksStockId", stockId, { root: true });
          }

          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPatient(context, id) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { patient: true });
      fetchPatient(id)
        .then(res => {
          const patient = res.data.body;

          context.commit("SET_PATIENT", patient);

          const poleStockId = patient.contracts.find(contract => contract.id === context.state.intervention.contractId)?.pole?.stockId;

          if (poleStockId) {
            context.dispatch("common/setSearchInStocksPoleStockId", poleStockId, { root: true });
          }

          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { patient: false });
        });
    });
  },
  fetchPrescriptionAddress(context, id) {
    return new Promise((resolve, reject) => {
      fetchPrescriptionAddresses({ query: { prescriptionAddresses: [id] } })
        .then(res => {
          if (res.data.body.results.length) {
            context.commit("SET_PRESCRIPTION_ADDRESS", res.data.body.results[0]);
          }
          context.dispatch("computePatientLocations");
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { prescriptionAddress: false });
        });
    });
  },
  fetchIntervenant(context, intervenantId) {
    return new Promise((resolve, reject) => {
      fetchUser(intervenantId)
        .then(res => {
          context.commit("SET_TECHNICIAN", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchInterventionForfait(context, forfaitIds) {
    return new Promise((resolve, reject) => {
      if (!isEmpty(forfaitIds)) {
        fetchForfaits({ query: { ids: forfaitIds } })
          .then(res => {
            const forfaits = res.data.body || null;
            context.commit("SET_INTERVENTION", { ...context.state.intervention, forfaits });
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve();
      }
    });
  },
  fetchConfrerePrescriber(context) {
    return new Promise((resolve, reject) => {
      const interventionContract = context.state.patient.contracts.find(contract => contract.id === context.state.intervention.contractId);
      const prescribersPrescriptionAddressIds = [];
      if (interventionContract.secondaryPrescriptionAddressId) {
        prescribersPrescriptionAddressIds.push(interventionContract.secondaryPrescriptionAddressId);
      }
      if (interventionContract.otherPrescriptionAddressIds?.length) {
        prescribersPrescriptionAddressIds.push(...interventionContract.otherPrescriptionAddressIds);
      }
      if (!prescribersPrescriptionAddressIds.length) {
        resolve();
        return;
      }
      fetchPrescribers({ query: { prescriptionAddresses: prescribersPrescriptionAddressIds } })
        .then(res => {
          const { intervention } = context.state;
          intervention.confrerePrescriber = res.data.body && res.data.body.results.length ? res.data.body.results[0] : null;
          context.commit("SET_INTERVENTION", intervention);
          resolve();
        })
        .catch(err => reject(err));
    });
  },
  fetchPatientCommunity(context) {
    return new Promise((resolve, reject) => {
      if (!context.state.patient.communityId) {
        resolve();
        return;
      }
      fetchCommunity(context.state.patient.communityId)
        .then(res => {
          context.commit("SET_COMMUNITY", res.data?.body || null);
          resolve();
        })
        .catch(err => reject(err));
    });
  },
  getLastCompteur(context) {
    return new Promise((resolve, reject) => {
      const { intervention, lastIntervention } = context.state;

      const formatLastCompteur = interventionWithCompteur => {
        const appareillage = interventionWithCompteur
          ? interventionWithCompteur.appareillages.find(appareillage => appareillage.metrics && appareillage.metrics.compteur)
          : null;
        /**
         * A compteur consists of a value and a date on which it was noted.
         * These two data are needed to compute observance
         */
        intervention.lastCompteur = appareillage ? { value: appareillage.metrics.compteur, date: interventionWithCompteur.dateTimeEnd } : null;
      };

      if (
        intervention.divisionId === "R" &&
        lastIntervention &&
        lastIntervention.appareillages &&
        lastIntervention.appareillages.some(appareillage => appareillage.metrics && appareillage.metrics.compteur)
      ) {
        formatLastCompteur(lastIntervention);
        context.commit("SET_INTERVENTION", intervention);

        resolve();
        return;
      }

      /* If we can't find compteur in previous intervention, we have to search further in the history */
      fetchInterventions({
        query: {
          contractId: intervention.contractId,
          dateTimeEnd: intervention.dateTimeStart ? moment(intervention.dateTimeStart).add(-1, "days") : moment(),
          lastCompteur: true,
          _id: { $ne: intervention._id }
        }
      })
        .then(res => {
          const interventionWithLastCompteur = get(res, "data.body.results.0");

          formatLastCompteur(interventionWithLastCompteur);
          context.commit("SET_INTERVENTION", intervention);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPreviousIntervention(context) {
    return new Promise((resolve, reject) => {
      const { intervention } = context.state;
      const query = { contractId: intervention.contractId, excludedIds: [intervention._id] };
      const sort = { dateTimeEnd: -1 };
      const keyDateTime = intervention.dateTimeEnd ? "dateTimeEnd" : "plannedDateTimeEnd";
      query.dateTimeEnd = intervention[keyDateTime] ? moment(intervention[keyDateTime]).toISOString() : null;

      fetchInterventions({ query, limit: 1, sort })
        .then(res => {
          const previousIntervention = get(res, "data.body.results.0");
          context.commit("SET_PREVIOUS_INTERVENTION", previousIntervention);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchInterventions(context, params) {
    return new Promise((resolve, reject) => {
      fetchInterventions({ query: params })
        .then(res => {
          const results = get(res, "data.body.results") || [];

          context.commit("SET_INTERVENTIONS", results);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setFeedbackMessage(context, { feedback, feedbackType }) {
    return new Promise(resolve => {
      if (feedbackType === "error") {
        context.commit("SET_FEEDBACK_MESSAGES_ERROR", feedback);
      } else if (feedbackType === "sucess") {
        context.commit("SET_FEEDBACK_MESSAGES_SUCCESS", feedback);
      }

      resolve();
    });
  },
  editIntervention(context, data) {
    return new Promise((resolve, reject) => {
      context.commit("SET_FEEDBACK_MESSAGES", { error: null, errorForm: null, success: null });
      context.commit("UPDATE_LOADING", { submit: true });
      const initialIntervention = context.state.rawIntervention;
      const {
        id,
        technician,
        prescriber,
        patientImc,
        plannedDate,
        locationIndex,
        iah,
        epworth,
        spO2Air,
        spO2Oxy,
        spO2Vni,
        spO2VniOxy,
        pulseRate,
        size,
        weight,
        files,
        ...intervention
      } = data;
      intervention.location = context.state.locations[locationIndex];

      if (technician) {
        intervention.intervenantId = technician._id;
        intervention.intervenantExternalId = technician.externalId;
      }

      if (plannedDate) {
        intervention.plannedDateTimeStart = plannedDate.start;
        intervention.plannedDateTimeEnd = plannedDate.end;
      }

      if (iah || epworth || spO2Air || spO2Oxy || spO2Vni || spO2VniOxy || size || weight || pulseRate) {
        intervention.metrics = { iah, epworth, spO2Air, spO2Oxy, spO2Vni, spO2VniOxy, size, weight, pulseRate };
      }

      intervention.contractId = initialIntervention.contractId;
      intervention.divisionId = initialIntervention.divisionId;

      /* If intervention is an installation, we need to remove BL because it comes from contract */
      intervention.files =
        intervention.typeId === interventionTypes.installation
          ? files?.filter(file => !(file.contentTypeId === fileTypes.bl && file.autoGenerated))
          : files;

      api
        .editIntervention(id, intervention)
        .then(response => {
          if (response.data.errorMessage) {
            context.commit("SET_FEEDBACK_MESSAGES", { error: response.data.errorMessage });
          }
          if (response.data.successMessage) {
            context.commit("SET_FEEDBACK_MESSAGES", { success: response.data.successMessage });
          }
          resolve();
        })
        .catch(error => {
          if (error.data.errorMessage) {
            context.commit("SET_FEEDBACK_MESSAGES", { error: error.data.errorMessage });
          }
          reject();
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { submit: false });
        });
    });
  },
  prepareIntervention(context, id) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { submit: true });
      context.commit("SET_FEEDBACK_MESSAGES", { success: null, error: null });
      api
        .prepareIntervention(id)
        .then(response => {
          context.commit("SET_FEEDBACK_MESSAGES", { success: response.data.successMessage });
          resolve();
        })
        .catch(error => {
          context.commit("SET_FEEDBACK_MESSAGES", { error: error?.data?.errorMessage || defaultErrorMessages.update });
          reject();
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { submit: false });
        });
    });
  },
  achieveIntervention(context, data) {
    return new Promise(resolve => {
      context.commit("UPDATE_LOADING", { submit: true });
      context.commit("SET_FEEDBACK_MESSAGES", { success: null, error: null });
      const { patient, locations } = context.state;
      const intervention = { ...context.state.intervention, ...data };
      intervention.location = locations[data.locationIndex];
      const contract = patient.contracts.find(contract => contract.id === intervention.contractId);

      api
        .createOrders({ interventionId: intervention._id, date: intervention.dateTimeStart })
        .catch(() => {
          context.commit("SET_FEEDBACK_MESSAGES", {
            error: defaultErrorMessages.stockMovment
          });
        })
        .finally(() => {
          api
            .achieveIntervention(intervention._id, { intervention, contract, patient })
            .then(response => {
              context.commit("SET_FEEDBACK_MESSAGES", { success: response.data.successMessage });
            })
            .catch(error => {
              context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.update });
            })
            .finally(() => {
              context.commit("UPDATE_LOADING", { submit: false });
              resolve();
            });
        });
    });
  },
  validateIntervention(context, id) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { submit: true });
      context.commit("SET_FEEDBACK_MESSAGES", { success: null, error: null });
      api
        .validateIntervention(id)
        .then(response => {
          context.commit("SET_FEEDBACK_MESSAGES", { success: response.data.successMessage });
          resolve();
        })
        .catch(error => {
          context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.update });
          reject();
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { submit: false });
        });
    });
  },
  deleteIntervention(context, id) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { submit: true });
      context.commit("SET_FEEDBACK_MESSAGES", { success: null, error: null });
      api
        .deleteIntervention(id)
        .then(response => {
          context.commit("SET_FEEDBACK_MESSAGES", { success: response.data.successMessage });
          resolve(response.data.successMessage);
        })
        .catch(error => {
          context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.update });
          context.commit("UPDATE_LOADING", { submit: false });
          reject();
        });
    });
  },
  deleteInterventionAndReplanned(context, { interventionId }) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { submit: true });
      api
        .deleteInterventionAndReplanned(interventionId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errorMessage = error.data?.errorMessage || defaultErrorMessages.update;

          reject(errorMessage);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { submit: false });
        });
    });
  },
  computePatientLocations(context) {
    const { patient, intervention } = context.state;

    const sortedTypedLocations = getPossibleInterventionTypedLocations(patient, intervention.contractId, intervention);
    const formattedLocations = getInterventionLocationOptions(sortedTypedLocations, __PLATFORM__);

    context.commit("SET_LOCATIONS", sortedTypedLocations);
    context.commit("SET_FORMATTED_LOCATIONS", formattedLocations);
  },
  updateFeedbackMessages(context, message) {
    const { feedbackMessages } = context.state;
    context.commit("SET_FEEDBACK_MESSAGES", { ...feedbackMessages, ...message });
  },
  generateReport(context) {
    context.commit("UPDATE_LOADING", { reportGeneration: true });
    context.commit("SET_FEEDBACK_MESSAGES", { success: null, error: null });
    api
      .generateInterventionReport(context.state.intervention._id)
      .then(response => {
        context.commit("SET_FEEDBACK_MESSAGES", { success: response.data.successMessage });
      })
      .catch(errorReport => {
        context.commit("SET_FEEDBACK_MESSAGES", { errorReport: errorReport.data.errorMessage });
      })
      .finally(() => {
        context.commit("UPDATE_LOADING", { reportGeneration: false });
        context
          .dispatch("fetchInterventionView", context.state.intervention._id)
          .catch(errorFetch => {
            context.commit("SET_FEEDBACK_MESSAGES", {
              errorFetch: errorFetch && errorFetch.data ? errorFetch.data.errorMessage : defaultErrorMessages.fetch
            });
          })
          .finally(() => {
            context.commit("UPDATE_LOADING", { submit: false });
          });
      });
  },
  checkPlanningSlotAvailability(context, data) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { submit: true });
      context.commit("SET_FEEDBACK_MESSAGES", { success: null, error: null, errorForm: null });
      checkPlanningSlotAvailability({
        query: {
          startDate: data.plannedDate.start,
          endDate: data.plannedDate.end,
          userId: data.technician._id,
          _id: context.state.intervention._id
        }
      })
        .then(response => {
          if (!response?.data?.body?.available) {
            context.commit("SET_FEEDBACK_MESSAGES", {
              errorForm: defaultErrorMessages.checkPlanningSlotAvailability
            });
            context.commit("UPDATE_LOADING", { submit: false });
            return resolve(false);
          }
          resolve(true);
        })
        .catch(error => {
          context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.default });
          context.commit("UPDATE_LOADING", { submit: false });
          reject();
        });
    });
  },
  cleanFeedbackMessages(context) {
    const { feedbackMessages } = context.state;
    Object.keys(feedbackMessages).forEach(key => {
      feedbackMessages[key] = null;
    });
    context.commit("SET_FEEDBACK_MESSAGES", feedbackMessages);
  },
  resetIntervention(context) {
    context.commit("RESET_STATE");
  },
  updateNextAction(context, nextAction) {
    context.commit("SET_NEXT_ACTION", nextAction);
  },
  updateNextRoute(context, nextRoute) {
    context.commit("SET_NEXT_ROUTE", nextRoute);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
