import { componentNameResolver } from "@common/helpers/settings/overrideResolver";

import getDefaultDivision from "@ui/helpers/_functions/getDefaultDivision";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.admin.users";
const settings = require(`@common/settings/${__PLATFORM__}`);

// User View
const UsersViewInformations = () => import(/* webpackChunkName: "user" */ "@/modules/users/view/_components/UsersViewInternalInformations");
const UsersViewStockCamion = () => import(/* webpackChunkName: "user" */ "@/modules/users/view/_components/UsersViewStockCamion");

const {
  usersRights: { VIEW_USER, CREATE_USER, GET_USER, EDIT_USER }
} = require("@common/services/acm/rights");

export default [
  {
    path: "users",
    alias: "utilisateurs",
    name: "users",
    redirect: () => `users/${getDefaultDivision()}`
  },
  {
    path: "users/:division",
    alias: "utilisateurs/:division",
    name: "usersList",
    component: () => {
      const component = componentNameResolver("IndexUsersList", settings.overridedModules);
      return import(`@/modules/users/list/${component}`);
    },
    meta: {
      title: localize(localPath, "title--usersList"),
      rights: [VIEW_USER, GET_USER]
    }
  },
  {
    path: "user/add",
    alias: "utilisateur/creation",
    component: () => {
      const component = componentNameResolver("IndexAddUser", settings.overridedModules);
      return import(`@/modules/users/add/${component}`);
    },
    children: [
      {
        path: "",
        name: "usersAdd",
        component: () => {
          const component = componentNameResolver("UsersAddForm", settings.overridedModules);
          return import(`@/modules/users/add/_components/${component}`);
        },
        meta: {
          title: localize(localPath, "title--userAdd"),
          rights: [CREATE_USER]
        },
        beforeEnter: (to, from, next) => {
          if (from.name !== "usersSearch") {
            next({ name: "usersSearch" });
          } else {
            next();
          }
        }
      },
      {
        path: "search",
        alias: "recherche",
        name: "usersSearch",
        component: () => {
          const component = componentNameResolver("UsersSearch", settings.overridedModules);
          return import(`@/modules/users/add/_components/${component}`);
        },
        meta: {
          title: localize(localPath, "title--userAddSearch"),
          rights: [CREATE_USER]
        }
      }
    ]
  },
  {
    path: "user/:id",
    alias: "utilisateur/:id",
    component: () => {
      const component = componentNameResolver("IndexUserView", settings.overridedModules);
      return import(`@/modules/users/view/${component}`);
    },
    children: [
      {
        path: "",
        name: "usersView",
        meta: {
          title: localize(localPath, "title--userView"),
          rights: [GET_USER]
        },
        component: UsersViewInformations
      },
      {
        path: "info/edit",
        alias: "informations/edition",
        name: "usersEditInternalInformations",
        meta: {
          title: localize(localPath, "title--userViewEdit"),
          rights: [EDIT_USER]
        },
        component: () => {
          const component = componentNameResolver("UsersEditInternalInformations", settings.overridedModules);
          return import(`@/modules/users/edit/_components/${component}`);
        }
      },
      {
        path: "stock-tech",
        alias: "stock-camion",
        name: "usersViewStock",
        meta: {
          title: localize(localPath, "title--userView"),
          rights: [GET_USER]
        },
        component: UsersViewStockCamion
      }
    ]
  }
];
