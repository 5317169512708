module.exports = {
  offlinePdf: true,
  authBan: true,
  interventions: true,
  reminders: true,
  planification: {
    global: false,
    onInterventionAchieved: false,
    onContractCreation: true,
    actionButtons: false
  },
  communityTour: false, //  FIXME 11/04/2024 Vu avec Margaux, on attend d'avoir des retours de MedPro pour savoir si on le désactive ou non
  faxes: true,
  sleepStudies: true,
  telesuiviAlerts: true,
  patientTelesuiviSynchroButton: true
};
