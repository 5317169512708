import http from "../../../services/http";

/**
 * @param {String} stockId
 * @param {Object} params
 * @returns {Promise<[Object]>}
 */
const fetchStockContent = (stockId, params) =>
  http.get(`/stocks/${stockId}`, {
    params,
    timeout: 300000
  });

export default fetchStockContent;
