import http from "../../../services/http";

/**
 * @param {Object} params
 * @returns {Promise<[Object]>}
 */
const fetchCommunityStockContent = (stockId, params) =>
  http.get(`/stocks/community/${stockId}`, {
    params,
    timeout: 300000
  });

export default fetchCommunityStockContent;
