/**
 * Format the stock to recover the deliveries
 * @param {Array} stock
 * @returns {Array}
 */
const formatCompleteStock = (stock = []) => {
  const itemDetails = [];
  for (const item of stock) {
    if (!item.details?.length) {
      itemDetails.push({
        product: item.product,
        productId: item.productId,
        quantity: item.available
      });
    } else {
      for (const detail of item.details) {
        itemDetails.push({
          product: item.product,
          productId: item.productId,
          ...detail
        });
      }
    }
  }

  return itemDetails;
};

export default formatCompleteStock;
