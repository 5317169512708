import { searchPatients, searchInsureds } from "@/modules/common/_api";

import * as api from "../_api";

const state = {
  patients: [],
  insureds: []
};

const getters = {
  patients: state => state.patients,
  insureds: state => state.insureds
};

const mutations = {
  SET_PATIENTS: (state, patients) => {
    state.patients = patients;
  },
  SET_INSUREDS: (state, insureds) => {
    state.insureds = insureds;
  }
};

const actions = {
  searchPatients(context, input) {
    return new Promise((resolve, reject) => {
      searchPatients({ query: { input }, skip: 0, limit: 40, source: "remote" })
        .then(res => {
          context.commit("SET_PATIENTS", res.data.body);
          return resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  searchInsureds(context, input) {
    return new Promise((resolve, reject) => {
      searchInsureds({ query: { input }, skip: 0, limit: 40, source: "remote" })
        .then(res => {
          context.commit("SET_INSUREDS", res.data.body);
          return resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  linkPatientToInsured(context, data) {
    const { beneficiaryId, insuredId } = data;
    return new Promise((resolve, reject) => {
      api
        .linkPatientToInsured(beneficiaryId, { insuredId })
        .then(() => {
          api
            .linkInsuredToPatient(insuredId, { beneficiaryId })
            .then(response => {
              resolve(response);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addPatient(context, data) {
    return api.addPatient(data);
  },
  addInsured(context, data) {
    return new Promise((resolve, reject) => {
      api
        .addInsured(data)
        .then(response => {
          if (response?.data?.body?._id) {
            api
              .linkPatientToInsured(data.beneficiaryIds[0], { insuredId: response?.data?.body?._id })
              .then(res => {
                resolve(res);
              })
              .catch(err => {
                reject(err);
              });
          }
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
