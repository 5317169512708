/* eslint-disable no-shadow */
import { searchPatients, searchIntervenants, fetchDeliveries, fetchPatient, unPairEquipment, getPatientsCount } from "@/modules/common/_api";
import * as api from "../_api";

import _patients from "./patients";
import _stock from "./stock";
import _entityInterventions from "./entityInterventions";

const deliveryStatuses = require("@common/constants/deliveryStatuses");

const state = {
  ..._patients.state,
  ..._stock.state,
  ..._entityInterventions.state,
  community: {},
  patientsCount: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  successMessage: null
};

const getters = {
  ..._patients.getters,
  ..._stock.getters,
  ..._entityInterventions.getters,
  community: state => state.community,
  patientsCount: state => state.patientsCount,
  successMessage: state => state.successMessage
};

const mutations = {
  ..._patients.mutations,
  ..._stock.mutations,
  ..._entityInterventions.mutations,
  SET_COMMUNITY: (state, community) => {
    state.community = community;
  },
  SET_PATIENTS_COUNT: (state, { division, count }) => {
    state.patientsCount[division] = count;
  },
  SET_SUCCESS_MESSAGE: (state, successMessage) => {
    state.successMessage = successMessage;
  }
};

const actions = {
  ..._patients.actions,
  ..._stock.actions,
  ..._entityInterventions.actions,
  resetData(context) {
    context.commit("SET_COMPLETE_COMMUNITY_STOCK", null);
  },
  searchIntervenantsInSamePoleAsCommunity(context, input) {
    const { community } = context.state;

    const communityPoleId = community.pole?._id || null;
    const poleIds = communityPoleId ? [communityPoleId] : [];

    return searchIntervenants({ query: { input, active: true, poleIds }, skip: 0, limit: 10 });
  },
  fetchCommunity(context, idCommunity) {
    return new Promise((resolve, reject) => {
      api
        .fetchCommunity(idCommunity)
        .then(res => {
          context.commit("SET_COMMUNITY", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPatientsCount(context, { query }) {
    return new Promise((resolve, reject) => {
      getPatientsCount({ ...query, communityId: context.state.community._id })
        .then(res => {
          context.commit("SET_PATIENTS_COUNT", { division: query.division.toLowerCase(), count: res.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSuccessMessage(context, successMessage) {
    context.commit("SET_SUCCESS_MESSAGE", successMessage);
  },
  searchIntervenantsLinkedToCommunityPole(context, input) {
    const { community } = context.state;

    const communityPoleId = community.pole?._id || null;
    const poleIds = communityPoleId ? [communityPoleId] : [];

    return searchIntervenants({ query: { input, active: true, poleIds }, skip: 0, limit: 10 });
  },
  searchPatientsInCommunity(context, input) {
    const { community } = context.state;

    if (community) {
      return searchPatients({ query: { input, communityId: community._id, activeStatus: ["active"] }, skip: 0, limit: 40, source: "local" });
    }
  },
  fetchDeliveries(context, contractId) {
    return new Promise((resolve, reject) => {
      fetchDeliveries({ query: { active: true, contractId, statusId: deliveryStatuses.delivered, isRecoverableDelivery: true } })
        .then(res => {
          const deliveries = res.data.body.results || [];

          resolve(deliveries);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeAppairage(context, { delivery, channelId }) {
    return new Promise((resolve, reject) => {
      unPairEquipment({ delivery, channelId })
        .then(() => resolve())
        .catch(errDesappairage => reject(errDesappairage));
    });
  },
  removeProducts(context, { deliveries, interventionId, recuperationMotifId, removalEntityInterventionId }) {
    return new Promise((resolve, reject) => {
      for (const deliveryItem of deliveries) {
        const { product, status, selected, ...delivery } = deliveryItem;

        const updatedDelivery = {
          ...delivery,
          removalEntityInterventionId,
          statusId: deliveryStatuses.toBeRemoved,
          removalInterventionId: interventionId
        };

        if (recuperationMotifId) {
          updatedDelivery.recuperationMotifId = recuperationMotifId;
        }
        api
          .updateDelivery(delivery._id, updatedDelivery)
          .then(() => {
            fetchPatient(delivery.patientId).then(patient => {
              const deliveryContract = patient.data.body.contracts.find(contract => contract.id === delivery.contractId);
              const isProductPaired =
                delivery.statusId === deliveryStatuses.delivered &&
                delivery.serialNumber &&
                deliveryContract.telesuiviAppairage &&
                product.telesuivi &&
                deliveryContract.telesuiviFabricantId === delivery.fabricantId;
              if (isProductPaired) {
                context.dispatch("removeAppairage", { delivery, channelId: deliveryContract.channelId }).catch(err => {
                  reject(err);
                });
              }
            });
          })
          .catch(err => {
            reject(err);
          });
      }
      resolve(true);
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
