const isEqual = require("lodash/isEqual");
const isObject = require("lodash/isObject");

const extractInterventionLocations = require("@common/helpers/interventions/extractInterventionLocations");
const { locationTypeIds } = require("@common/constants/locationTypes");

/**
 * @param {Object} patient
 * @param {Object} contractId
 * @param {Object} intervention @default null
 * @returns {Object} - possible typed locations for an intervention
 * @description
 * This function computes the locations of a patient and an intervention.
 * It returns a list of possible typed locations for an intervention.
 */

const getPossibleInterventionTypedLocations = (patient, contractId, intervention = null) => {
  /**
   * If intervention hasn't location yet OR if we can retrieve it in patient's locations,
   * locations will simply be the patient's locations.
   * Otherwise, we append the intervention's location at index 0 and then
   * add patient's locations. This edge case can happen if patient's locations changed.
   */
  const locations = extractInterventionLocations(patient, contractId, __PLATFORM__) || [];

  if (!isObject(intervention?.location)) {
    return locations;
  }

  if (intervention.location.type === locationTypeIds.poleVisit) {
    return [
      locations.find(location => location.type === locationTypeIds.poleVisit),
      ...locations?.filter(location => location.type !== locationTypeIds.poleVisit)
    ];
  }

  return [intervention.location, ...locations?.filter(location => !isEqual(location, intervention.location))];
};

module.exports = getPossibleInterventionTypedLocations;
