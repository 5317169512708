import { iconMedicalDevice } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import subMenuProducts from "./subMenuProducts";

const localPath = "menus.administration.products.products";

const menuProducts = {
  title: localize(localPath, "title"),
  slug: "products",
  icon: iconMedicalDevice,
  disabled: false,
  items: [subMenuProducts]
};

export default menuProducts;
