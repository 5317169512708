import { componentNameResolver } from "@common/helpers/settings/overrideResolver";
import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.admin.interventions";

const settings = require(`@/settings/${__PLATFORM__}`);

// View & Edit
const InterventionViewGeneralView = () =>
  import(/* webpackChunkName: "intervention" */ "@/modules/interventions/view/_components/InterventionEditGeneralInformations");

const InterventionPlanificationView = () =>
  import(/* webpackChunkName: "intervention" */ "@/modules/interventions/view/_components/InterventionPlanification");

const InterventionDispositifsMedicauxView = () =>
  import(/* webpackChunkName: "intervention" */ "@/modules/interventions/view/_components/InterventionDispositifsMedicaux");

const InterventionConsommablesView = () =>
  import(/* webpackChunkName: "intervention" */ "@/modules/interventions/view/_components/InterventionConsommables");

const InterventionQuestionnaireView = () =>
  import(/* webpackChunkName: "intervention" */ "@/modules/interventions/view/_components/InterventionQuestionnaire");

const InterventionAnomalyView = () => import(/* webpackChunkName: "intervention" */ "@/modules/interventions/view/_components/InterventionAnomaly");

const InterventionEditContract = () =>
  import(/* webpackChunkName: "intervention" */ "@/modules/interventions/edit/_components/InterventionEditContract");

const InterventionEditByPdf = () => import(/* webpackChunkName: "intervention" */ "@/modules/interventions/view/_components/InterventionEditByPdf");

const InterventionsWithAnomaly = () => import(/* webpackChunkName: "intervention" */ "@/modules/interventions/list/_components/WithAnomaly");
const InterventionWithRiskAnalysisToClose = () =>
  import(/* webpackChunkName: "intervention" */ "@/modules/interventions/list/_components/RiskAnalysisToClose");

const InterventionsPlanning = () => import(/* webpackChunkName: "intervention" */ "@/modules/interventions/planning");
const InterventionView = () => import(/* webpackChunkName: "intervention" */ "@/modules/interventions/view");
const InterventionsTour = () => import(/* webpackChunkName: "intervention" */ "@/modules/interventions/tour");
const InterventionsSingleTour = () => import(/* webpackChunkName: "intervention" */ "@/modules/interventions/tour/_components/SingleTour");
const InterventionsCalendar = () => import(/* webpackChunkName: "intervention" */ "@/modules/interventions/calendar");

const {
  interventionsRights: { ACCESS_INTERVENTION, WRITE_RISK_ANALYSIS },
  menuRights: { VIEW_MENU_ANOMALY_INTERVENTIONS },
  contractsRights: { EDIT_CONTRACT }
} = require("@common/services/acm/rights");

export default [
  {
    path: "visits/get-list",
    alias: "interventions/get-list",
    name: "interventions",
    component: () => {
      const component = componentNameResolver("IndexPageInterventionList", settings.default.overridedModules);
      return import(`@/modules/interventions/list/${component}`);
    },
    props: true,
    meta: {
      title: localize(localPath, "title--interventions"),
      rights: [ACCESS_INTERVENTION]
    }
  },
  {
    path: "visits/scheduling",
    alias: "interventions/planning",
    name: "interventionsPlanning",
    meta: {
      title: localize(localPath, "title--interventions"),
      rights: [ACCESS_INTERVENTION]
    },
    component: InterventionsPlanning
  },
  {
    path: "visits/calendar",
    alias: "interventions/calendrier",
    name: "interventionsCalendar",
    meta: {
      title: localize(localPath, "title--interventionsCalendar"),
      rights: [ACCESS_INTERVENTION]
    },
    component: InterventionsCalendar
  },
  {
    path: "visits/to-do",
    alias: "interventions/a-realiser",
    name: "interventionsReadyStatus",
    meta: {
      title: localize(localPath, "title--interventionsReadyStatus"),
      rights: [ACCESS_INTERVENTION]
    },
    component: () => {
      const component = componentNameResolver("ReadyStatus", settings.default.overridedModules);
      return import(`@/modules/interventions/list/_components/${component}`);
    }
  },
  {
    path: "visits/pending",
    alias: "interventions/en-attente",
    name: "interventionsWaitingStatus",
    meta: {
      title: localize(localPath, "title--interventionsWaitingStatus"),
      rights: [ACCESS_INTERVENTION]
    },
    component: () => {
      const component = componentNameResolver("WaitingStatus", settings.default.overridedModules);
      return import(`@/modules/interventions/list/_components/${component}`);
    }
  },
  {
    path: "visits/to-be-prepared",
    alias: "interventions/en-préparation",
    name: "interventionsToBePreparedStatus",
    meta: {
      title: localize(localPath, "title--interventionsToBePreparedStatus"),
      rights: [ACCESS_INTERVENTION]
    },
    component: () => {
      const component = componentNameResolver("ToBePreparedStatus", settings.default.overridedModules);
      return import(`@/modules/interventions/list/_components/${component}`);
    }
  },
  {
    path: "visits/to-be-validated-and-checked",
    alias: "interventions/a-valider-et-controler",
    name: "interventionsListValidatedStatus",
    meta: {
      title: localize(localPath, "title--interventionsListValidatedStatus"),
      rights: [ACCESS_INTERVENTION]
    },
    component: () => {
      const component = componentNameResolver("InterventionsListValidatedStatus", settings.default.overridedModules);
      return import(`@/modules/interventions/list/_components/${component}`);
    }
  },
  {
    path: "visits/in-anomaly",
    alias: "interventions/en-anomalie",
    name: "interventionsWithAnomaly",
    meta: {
      title: localize(localPath, "title--interventionsWithAnomaly"),
      rights: [ACCESS_INTERVENTION, VIEW_MENU_ANOMALY_INTERVENTIONS]
    },
    component: InterventionsWithAnomaly
  },
  {
    path: "interventions/risk-analysis-to-close",
    name: "interventionsWithRiskAnalysisToClose",
    meta: {
      title: localize(localPath, "title--interventionsWithRiskAnalysisToClose"),
      rights: [WRITE_RISK_ANALYSIS]
    },
    component: InterventionWithRiskAnalysisToClose
  },
  {
    path: "visit/:id",
    alias: "intervention/:id",
    name: "interventionViewParent",
    component: InterventionView,
    children: [
      {
        path: "",
        name: "interventionView",
        meta: {
          title: localize(localPath, "title--interventionView"),
          rights: [ACCESS_INTERVENTION]
        },
        component: InterventionViewGeneralView
      },
      {
        path: "scheduling",
        alias: "planification",
        name: "interventionPlanification",
        meta: {
          title: localize(localPath, "title--interventionView"),
          rights: [ACCESS_INTERVENTION]
        },
        component: InterventionPlanificationView
      },
      {
        path: "medical-devices",
        alias: "dispositifs-medicaux",
        name: "interventionDispositifsMedicaux",
        meta: {
          title: localize(localPath, "title--interventionView"),
          rights: [ACCESS_INTERVENTION]
        },
        component: InterventionDispositifsMedicauxView
      },
      {
        path: "consumables",
        alias: "consommables",
        name: "interventionConsommables",
        meta: {
          title: localize(localPath, "title--interventionView"),
          rights: [ACCESS_INTERVENTION]
        },
        component: InterventionConsommablesView
      },
      {
        path: "questionnaire",
        name: "interventionQuestionnaire",
        meta: {
          title: localize(localPath, "title--interventionView"),
          rights: [ACCESS_INTERVENTION]
        },
        component: InterventionQuestionnaireView
      },
      {
        path: "questionnaires",
        alias: "questionnaires",
        name: "interventionViewQuestionnaires",
        meta: {
          title: localize(localPath, `title--interventionView`),
          rights: [ACCESS_INTERVENTION]
        },
        component: () => {
          const component = componentNameResolver("TabsInterventionViewQuestionnaires", settings.default.overridedModules);
          return import(`@/modules/interventions/view/_components/questionnaires/${component}`);
        }
      },
      {
        path: "contract",
        alias: "contrat",
        name: "interventionContract",
        meta: {
          title: localize(localPath, "title--interventionView"),
          rights: [ACCESS_INTERVENTION]
        },
        // component: InterventionContractView,
        component: () => {
          const component = componentNameResolver("InterventionContract", settings.default.overridedModules);
          return import(`@/modules/interventions/view/_components/contract/${component}`);
        }
      },
      {
        path: "anomaly",
        alias: "anomalie",
        name: "interventionAnomaly",
        meta: {
          title: localize(localPath, "title--interventionView"),
          rights: [ACCESS_INTERVENTION]
        },
        component: InterventionAnomalyView
      },
      {
        path: "contract/edit",
        alias: "contrat/edition",
        name: "interventionContractEdit",
        meta: {
          title: localize(localPath, "title--interventionView"),
          rights: [EDIT_CONTRACT]
        },
        component: InterventionEditContract
      },
      {
        path: "edit-from-pdf",
        alias: "edition-depuis-pdf",
        name: "interventionEditByPdf",
        meta: {
          title: localize(localPath, "title--interventionView"),
          rights: [ACCESS_INTERVENTION]
        },
        component: InterventionEditByPdf
      }
    ]
  },
  {
    path: "tours",
    alias: "tournees",
    name: "interventionsTour",
    component: InterventionsTour,
    meta: {
      title: localize(localPath, "title--interventionsTour"),
      rights: [ACCESS_INTERVENTION]
    }
  },
  {
    path: "my-tour",
    alias: "ma-tournee",
    name: "interventionsSingleTour",
    component: InterventionsSingleTour,
    meta: {
      title: localize(localPath, "title--interventionsSingleTour"),
      rights: [ACCESS_INTERVENTION]
    }
  }
];
