/* eslint-disable no-shadow */
import isEmpty from "lodash/isEmpty";

import { settings } from "@/bootstrap";

import { fetchFile, uploadPrivateFile } from "@/modules/common/_api";

import mergeMainAndOtherPrescriptionAddressIds from "@/helpers/_functions/mergeMainAndOtherPrescriptionAddressIds";

import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPrivateFile(formdata);
  },
  editPrescriberInternals(context, data) {
    /*
     * Need to clean prescriber object and map delegates and technicians because they are composed
     * of array of objects (this format is returned by input-search) but we need a different format (cf. json schema)
     */
    const { _id, delegates, technicians, prescriptionAddresses, patientsCountByDivision, ...prescriber } = data;

    if (prescriptionAddresses) {
      prescriber.prescriptionAddressIds = prescriptionAddresses.map(address => address._id);
    }

    if (delegates) {
      prescriber.delegates = delegates.map(delegate => {
        let id = delegate._id;
        if (delegate.delegate) {
          id = delegate.delegate._id;
        }
        return { id, divisionId: delegate.divisionId, date: delegate.date };
      });
    }

    if (technicians) {
      prescriber.technicians = technicians.map(technician => {
        let id = technician._id;
        if (technician.technician) {
          id = technician.technician._id;
        }
        return { id, divisionId: technician.divisionId, date: technician.date };
      });
    }

    return api.editPrescriberInternals(_id, prescriber);
  },
  editPrescriberInternalsMedpro(context, data) {
    const { _id, mainPrescriptionAddressId, otherPrescriptionAddressIds, ...prescriber } = data;

    prescriber.prescriptionAddressIds = mergeMainAndOtherPrescriptionAddressIds(mainPrescriptionAddressId, otherPrescriptionAddressIds);

    prescriber.divisionIds = [settings.default.defaultDivisionId];

    return api.editPrescriberInternals(_id, prescriber);
  },
  editPrescriberGlobals(context, data) {
    const { _id, speciality, title, pole, patientsCountByDivision, ...prescriber } = data;
    return api.editPrescriberGlobals(_id, prescriber);
  },
  editPrescriberProtocols(context, data) {
    const {
      _id,
      patientsCountByDivision,
      depOrdoInitial,
      firstRenou,
      annualRenou,
      consultationLetter,
      administrativeProtocols,
      careFiles,
      communicationProtocols,
      ...prescriber
    } = data;

    if (depOrdoInitial || firstRenou || annualRenou || consultationLetter) {
      prescriber.administrativeProtocols = {
        depOrdoInitial,
        firstRenou,
        annualRenou,
        consultationLetter
      };
    }

    if (communicationProtocols) {
      prescriber.communicationProtocols = communicationProtocols.map(({ user, ...item }) => ({ ...item, userId: user ? user._id : undefined }));
    }

    if (isEmpty(careFiles)) {
      prescriber.careFiles = [];
    } else {
      prescriber.files = careFiles;
    }

    return api.editPrescriberProtocols(_id, prescriber);
  },
  editPrescriberDap(context, data) {
    const { _id, cpsCard, idCard, signature, canRenew, verifiedByOrdoclic, ...prescriber } = data;

    if (!prescriber.files) {
      prescriber.files = {};
    }

    prescriber.files.cpsCard = cpsCard || {};
    prescriber.files.idCard = idCard || {};
    prescriber.renewalInfos.canRenew = canRenew;
    prescriber.renewalInfos.signatureVerified = verifiedByOrdoclic;

    return api.editPrescriberDap(_id, prescriber);
  },
  addOrdoclicAccount(context, prescriber) {
    const { _id, label, number, street, zipCode, locality, finess, ...prescriberToUpdate } = prescriber;

    prescriberToUpdate.locations = [
      {
        label,
        number,
        street,
        zipCode,
        locality,
        finess
      }
    ];

    return api.createOrdoclicPrescriberAccount(_id, prescriberToUpdate);
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveSignature(context, data) {
    return api.saveSignature(data);
  },
  checkPrescriberEmailAvailability(context, params) {
    return api.checkPrescriberEmailAvailability(params);
  },
  checkPrescriberRppsAvailability(context, params) {
    return api.checkPrescriberRppsAvailability(params);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
