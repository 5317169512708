import bindContactData from "@/helpers/_functions/bindContactData";

import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  searchContacts(context, input) {
    return api.searchContacts({ query: { input, active: true }, skip: 0, limit: 10, source: "local" });
  },
  addContact(context, data) {
    return new Promise((resolve, reject) => {
      const contact = bindContactData(data);

      if (!contact) {
        return reject();
      }

      return api
        .addContact(contact)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
