/* eslint-disable no-shadow */
import { fetchStockContent, searchIntervenants, createStockMovement } from "@/modules/common/_api";
import searchThroughTaxonomy from "@/helpers/_functions/searchThroughTaxonomy";
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import defaultSuccessMessages from "@/helpers/_constants/defaultSuccessMessages";
import * as api from "../_api";

const state = {
  stockCamion: null,
  stockTaxonomie: null,
  lastRequestId: null,
  userPoles: [],
  feedbackMessages: {
    caution: null,
    errorTelesuivi: null,
    errorFilePreview: null,
    errorEquipement: null,
    errorAppairage: null,
    equipementAvailabilityCaution: null,
    errorCreateStockMovement: null,
    successCreateStockMovement: null
  },
  loading: {
    replacement: false,
    telesuivi: false,
    appairage: false,
    validate: false
  }
};

const getters = {
  stockCamion: state => state.stockCamion,
  feedbackMessages: state => state.feedbackMessages,
  loading: state => state.loading,
  getCurrentUserPoles: (state, get, rootState, rootGetters) => {
    state.userPoles = rootGetters["common/getCurrentUserPoles"].filter(pole => !pole.legacy);
    return state.userPoles;
  }
};

const mutations = {
  SET_STOCK_CAMION: (state, stockCamion) => {
    state.stockCamion = stockCamion;
  },
  SET_STOCK_TAXONOMIE: (state, stockTaxonomie) => {
    state.stockTaxonomie = stockTaxonomie;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_FEEDBACK_MESSAGES: (state, messages) => {
    state.feedbackMessages = messages;
  },
  UPDATE_LOADING: (state, loadingItems) => {
    state.loading = { ...state.loading, ...loadingItems };
  }
};

const actions = {
  updateFeedbackMessages(context, message) {
    const { feedbackMessages } = context.state;
    context.commit("SET_FEEDBACK_MESSAGES", { ...feedbackMessages, ...message });
  },
  searchProducts(context, query) {
    const { stockTaxonomie } = context.state;

    return new Promise(resolve => {
      resolve(searchThroughTaxonomy(stockTaxonomie, query));
    });
  },
  searchIntervenants(context, input) {
    return searchIntervenants({ query: { input, active: true }, skip: 0, limit: 10 });
  },
  updateStockTaxonomie(context) {
    const { stockCamion } = context.state;
    const stockTaxonomie = stockCamion ? stockCamion.map(item => ({ ...item.product, value: item.productId })) : null;
    context.commit("SET_STOCK_TAXONOMIE", stockTaxonomie);
  },
  fetchStock(context, { requestId, stockId, ...params }) {
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      if (!stockId) {
        return resolve(null);
      }
      fetchStockContent(stockId, params)
        .then(res => {
          const { lastRequestId } = context.state;
          if (lastRequestId !== requestId) {
            return resolve();
          }
          context.commit("SET_STOCK_CAMION", res.data.body);
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  exportStock(context, { stockId, ...params }) {
    return new Promise((resolve, reject) => {
      if (!stockId) {
        return resolve([]);
      }
      api
        .exportStock(stockId, params)
        .then(result => resolve(result))
        .catch(err => reject(err));
    });
  },
  searchIntervenantsWithExternalId(context, input) {
    return new Promise((resolve, reject) => {
      searchIntervenants({
        query: { input, active: true, withExternalId: true, withEmail: true, poles: [context.state.userPoles[0]._id] },
        skip: 0,
        limit: 40,
        source: "local"
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  validateStockTransfert(context, params) {
    context.commit("UPDATE_LOADING", { validate: true });
    context.dispatch("updateFeedbackMessages", { errorCreateStockMovement: null, successCreateStockMovement: null });

    const { deliveries, stockIdStart, stockIdEnd } = params;

    if (!deliveries || !stockIdStart || !stockIdEnd) {
      context.dispatch("updateFeedbackMessages", { errorCreateStockMovement: defaultErrorMessages.stockMovment });
      context.commit("UPDATE_LOADING", { validate: false });
      return;
    }

    const formattedDeliveries = deliveries.map(delivery => {
      return {
        productId: delivery.productId,
        product: {
          _id: delivery.product._id,
          unitId: delivery.product.unitId
        },
        quantity: delivery.quantity,
        serialNumber: delivery.serialNumber,
        batchNumber: delivery.batchNumber,
        agaNumber: delivery.agaNumber,
        fromStockId: stockIdStart,
        toStockId: stockIdEnd
      };
    });

    return new Promise((resolve, reject) => {
      createStockMovement({
        deliveries: formattedDeliveries,
        fromStockId: stockIdStart,
        toStockId: stockIdEnd
      })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          context.commit("SET_FEEDBACK_MESSAGES", { successCreateStockMovement: defaultSuccessMessages.stockMovment });
          resolve();
        })
        .catch(err => {
          context.dispatch("updateFeedbackMessages", { errorCreateStockMovement: defaultErrorMessages.stockMovment });
          reject(err);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { validate: false });
        });
    });
  },
  setStockProductsTaxonomies(context, stock) {
    context.commit("SET_STOCK_TAXONOMIE", stock?.map(item => ({ ...item.product, value: item.productId })) || null);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
