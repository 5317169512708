import * as api from "../_api";

const state = {
  faxes: []
};

const getters = {
  getFaxes: state => state.faxes
};

const mutations = {
  SET_FAXES: (state, faxes) => {
    state.faxes = faxes;
  }
};

const actions = {
  sendFax(context, data) {
    return new Promise((resolve, reject) => {
      api
        .sendFax(data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchFaxById(context, faxId) {
    return new Promise((resolve, reject) => {
      api
        .fetchFaxDocument(faxId)
        .then(response => {
          resolve(response.data.body);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchFaxesByContractId(context, { contractId, params }) {
    return new Promise((resolve, reject) => {
      api
        .fetchFaxesByContractId({ contractId, params })
        .then(response => {
          context.commit("SET_FAXES", response.data.body);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
