import { updateInterventionQuestionnaire, fetchInterventionQuestionnaire, fetchPatientLastInterventionQuestionnaire } from "../_api";

const interventionStatuses = require("@common/constants/interventionStatuses");

const state = {
  questionnaire: {}
};

const getters = {
  questionnaire: state => state.questionnaire
};

const mutations = {
  SET_QUESTIONNAIRE: (state, questionnaire) => {
    state.questionnaire = questionnaire;
  }
};

const actions = {
  updateInterventionQuestionnaire(context, dataToSave) {
    return updateInterventionQuestionnaire({ questionnaire: dataToSave });
  },
  fetchInterventionQuestionnaire(context, intervention) {
    return new Promise((resolve, reject) => {
      fetchInterventionQuestionnaire(intervention._id)
        .then(res => {
          if (res.data?.body) {
            context.commit("SET_QUESTIONNAIRE", res.data.body);
            resolve();
            return;
          }

          // Case where we prefill questionnaire data with the last interventionQuestionnaire of the patient
          if (
            [
              interventionStatuses.waiting,
              interventionStatuses.toBePrepared,
              interventionStatuses.toBeDelivered,
              interventionStatuses.confirmed
            ].includes(intervention.statusId)
          ) {
            fetchPatientLastInterventionQuestionnaire(intervention.patientId).then(response => {
              const prefilledQuestionnaireData = response.data?.body || null;

              // Delete _id to avoid cross questionnaire data saving
              if (prefilledQuestionnaireData) {
                delete prefilledQuestionnaireData._id;
              }

              context.commit("SET_QUESTIONNAIRE", prefilledQuestionnaireData || {});
              resolve();
            });
          } else {
            resolve();
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions };
