module.exports = {
  /* KEY-VALUE */
  abpm: "abpm",
  capnographie: "capnographie",
  coaching: "coaching",
  customerService: "customerService",
  delivery: "delivery",
  discontinue: "discontinue",
  equipmentPurchase: "equipmentPurchase",
  hsat: "hsat",
  hsatOnTherapy: "hsatOnTherapy",
  installation: "installation",
  materioVigilance: "materioVigilance",
  materioVigilanceResmed: "materioVigilanceResmed",
  newPatientConsult: "newPatientConsult",
  o2Assessment: "o2Assessment",
  obs: "obs",
  oneMonthDelivery: "oneMonthDelivery",
  onDemand: "onDemand",
  other: "other",
  oximetry: "oximetry",
  oximetryOnly: "oximetryOnly",
  partialRecovery: "partialRecovery",
  pharmacistVisit: "pharmacistVisit",
  phoneCall: "phoneCall",
  prescriptionChange: "prescriptionChange",
  prescriptionEdit: "prescriptionEdit",
  provideSupplies: "provideSupplies",
  recovery: "recovery",
  redoHsat: "redoHsat",
  remainder: "remainder",
  renewal: "renewal",
  renewalIni: "renewalIni",
  sav: "customerService",
  sixMonthDelivery: "sixMonthDelivery",
  suppliesDelivery: "suppliesDelivery",
  teleobs: "teleobs",
  teleobsAuto: "teleobsAuto",
  threeMonthDelivery: "threeMonthDelivery",
  titration: "titration",
  training: "training",
  twoMonthDelivery: "twoMonthDelivery",
  walkIn: "walkIn",

  /* GROUP OF INTERVENTIONS */
  autoCRGeneration: [
    "capnographie",
    "coaching",
    "installation",
    "obs",
    "onDemand",
    "oximetry",
    "oximetryOnly",
    "partialRecovery",
    "phoneCall",
    "prescriptionChange",
    "prescriptionEdit",
    "recovery",
    "renewal",
    "renewalIni",
    "teleobs",
    "teleobsAuto",
    "titration"
  ],
  CPIGeneration: ["installation"],
  CPOGeneration: [
    "capnographie",
    "coaching",
    "customerService",
    "titration",
    "materiovigilance",
    "materiovigilanceResmed",
    "obs",
    "onDemand",
    "oximetry",
    "oximetryOnly",
    "phoneCall",
    "prescriptionChange",
    "prescriptionEdit",
    "renewal",
    "renewalIni",
    "teleobs",
    "teleobsAuto"
  ],
  CPRGeneration: ["partialRecovery", "recovery"],
  deliveries: ["delivery", "oneMonthDelivery", "twoMonthDelivery", "threeMonthDelivery", "sixMonthDelivery", "suppliesDelivery"],
  ignoreTelesuiviConsentState: ["phoneCall"],
  pharmacistVisits: ["pharmacistVisit"],
  productsReminderPrefill: ["delivery", "installation", "oneMonthDelivery", "twoMonthDelivery", "threeMonthDelivery", "sixMonthDelivery"],
  smsSend: [
    "capnographie",
    "coaching",
    "customerService",
    "delivery",
    "installation",
    "obs",
    "onDemand",
    "oximetry",
    "oximetryOnly",
    "partialRecovery",
    "pharmacistVisit",
    "recovery",
    "renewal",
    "suppliesDelivery",
    "titration"
  ],
  withoutDm: ["suppliesDelivery"]
};
