import bindContactData from "@/helpers/_functions/bindContactData";

import { editContact } from "../_api";

const state = {
  contact: {}
};

const getters = {};

const mutations = {};

const actions = {
  editContact(context, data) {
    return new Promise((resolve, reject) => {
      const contact = bindContactData(data);

      if (!contact) {
        return reject();
      }

      return editContact(contact)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
