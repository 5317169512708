import { synchronizePatientTelesuivi } from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  synchronizePatientTelesuivi(context, contractId) {
    return synchronizePatientTelesuivi(contractId);
  }
};

export default { state, getters, mutations, actions };
