import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  searchCaisses(context, input) {
    return api.searchCaisses({ query: { input }, skip: 0, limit: 10, source: "local" });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
