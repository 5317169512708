import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";

import { getContactTypeDiabete } from "@common/helpers/getContactType";

const bindContactData = data => {
  if (!data || typeof data !== "object" || !Object.keys(data).length) {
    return {};
  }

  const { file, locations, emails, ...contact } = data;

  contact.emails = isArray(emails) ? emails.filter(item => item).map(item => item.email.toLowerCase()) : [];

  const contactType = getContactTypeDiabete(contact.typeId);

  if (contactType?.hasLabel) {
    delete contact.firstname;
    delete contact.lastname;
  } else {
    delete contact.label;
  }

  if (!contactType.hasTraining) {
    delete contact.trainings;
  }

  if (!isBoolean(contact.active)) {
    contact.active = true;
  }

  contact.locations = locations.map(location => {
    const cleanedLocation = {};

    for (const key of Object.keys(location)) {
      cleanedLocation[key] = location[key] === "" ? null : location[key];
    }

    return cleanedLocation;
  });

  return contact;
};

export default bindContactData;
