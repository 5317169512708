import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import defaultSuccessMessages from "@/helpers/_constants/defaultSuccessMessages";
import { fetchUsers, fetchStockContent } from "@/modules/common/_api";
import * as api from "../_api";
import { createEntitySalesOrder, createEntityRecup, createEntityStockMovement } from "../../common/_api";

const state = {
  anomalyUsers: [],
  stockCamion: null
};

const getters = {
  anomalyUsers: state => state.anomalyUsers,
  stockCamion: state => state.stockCamion
};

const mutations = {
  UPDATE_ANOMALY_USERS: (state, anomalyUsers) => {
    state.anomalyUsers = anomalyUsers;
  },
  SET_STOCK_CAMION: (state, stockCamion) => {
    state.stockCamion = stockCamion;
  }
};

const actions = {
  fetchAnomalyUsers(context) {
    return new Promise((resolve, reject) => {
      const userIds = context.state.entityIntervention.anomaly.history.map(item => item.resolvedBy).filter(userId => userId);
      if (!userIds.length) {
        return resolve();
      }
      fetchUsers({ query: { ids: userIds } })
        .then(res => {
          const results = res.data && res.data.body ? res.data.body.results : [];
          context.commit("UPDATE_ANOMALY_USERS", results);
          resolve();
        })
        .catch(reject);
    });
  },
  updateAnomalyStatus(context) {
    const userId = context.rootState.login.user._id;
    return api.updateAnomalyStatus(context.state.entityIntervention._id, { userId });
  },
  triggerStockMovement(context) {
    return new Promise((resolve, reject) => {
      Promise.allSettled([
        createEntityStockMovement({ entityInterventionId: context.state.entityIntervention._id }),
        createEntitySalesOrder({ entityInterventionId: context.state.entityIntervention._id }),
        createEntityRecup({ entityInterventionId: context.state.entityIntervention._id })
      ])
        .then(([entityOrderResult, entityRecupResult]) => {
          if ([entityOrderResult.status, entityRecupResult.status].includes("rejected")) {
            reject({ data: { errorMessage: defaultErrorMessages.stockMovment } });
            return;
          }
          context
            .dispatch("updateAnomalyStatus")
            .then(() => resolve({ data: { successMessage: defaultSuccessMessages.stockMovment } }))
            .catch(errUpdate => reject(errUpdate));
        })
        .catch(errMovment => reject(errMovment));
    });
  },
  syncAnomalyEntityDeliveriesStock(context) {
    return new Promise((resolve, reject) => {
      Promise.all([
        api.syncAnomalyMachinesStock(context.state.entityIntervention._id),
        api.syncAnomalyAgaProductsStock(context.state.entityIntervention._id),
        context.dispatch("fetchStockCamion")
      ])
        .then(([res]) => resolve(res))
        .catch(([err]) => reject(err));
    });
  },
  fetchStockCamion(context) {
    return new Promise((resolve, reject) => {
      const { technician } = context.state;
      if (!technician?.stockId) {
        return resolve(null);
      }
      fetchStockContent(technician.stockId, {})
        .then(res => {
          context.commit("SET_STOCK_CAMION", res.data.body || []);
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions };
