const { SECRETAIRE_FACTURATION } = require("@common/constants/roles");

export default [
  {
    id: "reminders",
    description: "Reminders for the actual day",
    active: true
  },
  {
    id: "achievedInterventions",
    description: "Interventions with achieved status",
    active: true,
    roles: [SECRETAIRE_FACTURATION]
  }
];
