import getDefaultDivision from "@ui/helpers/_functions/getDefaultDivision";
import { componentNameResolver } from "@common/helpers/settings/overrideResolver";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.admin.patients";

const settings = require(`@/settings/${__PLATFORM__}`);

const PatientListHsat = () => import(/* webpackChunkName: "patient-list" */ "@/modules/patients/HsatPatientsListAdmin");

// Patient View Segments
const PatientViewMonitoring = () => import(/* webpackChunkName: "patient-view" */ "@/modules/patient/view/_components/PatientViewMonitoring");
const PatientViewDiabete = () => import(/* webpackChunkName: "patient-view" */ "@/modules/patient/view/_components/PatientViewDiabete");

// Patient Add Segments
const PatientAddSearch = () => import(/* webpackChunkName: "patient-add" */ "@/modules/patient/add/_components/PatientAddSearch");
const InsuredAddForm = () => import(/* webpackChunkName: "patient-add" */ "@/modules/patient/add/_components/InsuredAddForm");
const InsuredAddSearch = () => import(/* webpackChunkName: "patient-add" */ "@/modules/patient/add/_components/InsuredAddSearch");

// Patient Edit Segments
const PatientEditContractDiabete = () =>
  import(/* webpackChunkName: "patient-view" */ "@/modules/patient/edit/_components/PatientEditContractDiabete");

// Patient Planification Segments
const PatientPlanification = () => import(/* webpackChunkName: "patient-view" */ "@/modules/patient/planification");

const PatientAdd = () => import(/* webpackChunkName: "patient-add" */ "@/modules/patient/add");

// Patient recherche
const PatientSearch = () => import(/* webpackChunkName: "patient-search" */ "@/modules/patient/search");

// Patient Lift Form
const PatientLiftFormView = () => import(/* webpackChunkName: "patient-lift-form-view" */ "@/modules/patient-lift-form/view");

const PatientViewReminders = () => import(/* webpackChunkName: "patient-view-reminders" */ "@/modules/reminders/view/PatientViewReminders");

const {
  patientsRights: { CREATE_PATIENT, ACCESS_PATIENT, EDIT_PATIENT, PATIENT_VIEW_MONITORING },
  menuRights: { VIEW_MENU_SEARCH_PATIENT }
} = require("@common/services/acm/rights");

export default [
  {
    path: "patients/search",
    alias: "patients/recherche",
    name: "patientSearch",
    component: PatientSearch,
    meta: {
      title: localize(localPath, `title--patientSearch`),
      rights: [ACCESS_PATIENT, VIEW_MENU_SEARCH_PATIENT]
    }
  },
  {
    path: "patients/hsat",
    name: "patientsListHsat",
    component: PatientListHsat,
    props: true,
    meta: {
      title: localize(localPath, `title--patientsListHsat`),
      rights: [ACCESS_PATIENT]
    }
  },
  {
    path: "patients",
    name: "patients",
    redirect: () => `patients/${getDefaultDivision()}`
  },
  {
    path: "patients/:division",
    name: "patientsList",
    component: () => {
      const component = componentNameResolver("PatientsListAdmin", settings.default.overridedModules);
      return import(`@/modules/patients/${component}`);
    },
    props: true,
    meta: {
      title: localize(localPath, `title--patientsList`),
      rights: [ACCESS_PATIENT]
    }
  },
  {
    path: "patient/add",
    alias: "patient/creation",
    component: PatientAdd,
    children: [
      {
        path: "",
        name: "patientAdd",
        props: true,
        component: () => {
          const component = componentNameResolver("PatientAddForm", settings.default.overridedModules);
          return import(`@/modules/patient/add/_components/${component}`);
        },
        meta: {
          title: localize(localPath, `title--patientAdd`),
          rights: [CREATE_PATIENT]
        },
        beforeEnter: (to, from, next) => {
          if (from.name === "patientViewRespiratoire" && to.params.patient) {
            return next();
          }
          if (["patientAddSearch", "contractsAddPatient"].includes(from.name)) {
            return next();
          }
          next({ name: "patientAddSearch" });
        }
      },
      {
        path: "search",
        alias: "recherche",
        name: "patientAddSearch",
        component: PatientAddSearch,
        meta: {
          title: localize(localPath, `title--patientAddSearch`),
          rights: [CREATE_PATIENT]
        }
      },
      {
        path: "insured/search",
        name: "insuredAddSearch",
        component: InsuredAddSearch,
        props: true,
        meta: {
          title: localize(localPath, `title--insuredAddSearch`),
          rights: [CREATE_PATIENT]
        },
        beforeEnter: (to, from, next) => {
          if (from.name !== "patientAdd") {
            next({ name: "patientAddSearch" });
          } else {
            next();
          }
        }
      },
      {
        path: "insured",
        alias: "ouvrant-droit",
        name: "patientAddInsured",
        component: InsuredAddForm,
        props: true,
        meta: {
          title: localize(localPath, `title--patientAddInsured`),
          rights: [CREATE_PATIENT]
        },
        beforeEnter: (to, from, next) => {
          if (from.name !== "insuredAddSearch") {
            next({ name: "patientAddSearch", params: to.params });
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: "patient/lift-form/:phone",
    alias: "patient/levee-fiche/:phone",
    name: "patientLiftFormView",
    component: PatientLiftFormView,
    meta: {
      title: localize(localPath, `title--patientView`)
    }
  },
  {
    path: "patient/:id",
    name: "patientViewParent",
    component: () => {
      const component = componentNameResolver("PatientView", settings.default.overridedModules);
      return import(`@/modules/patient/view/${component}`);
    },
    children: [
      {
        path: "",
        name: "patientView",
        meta: {
          title: localize(localPath, `title--patientView`),
          rights: [ACCESS_PATIENT]
        },
        redirect: to => {
          if (settings.default?.defaultDivisionId === "D") {
            return { name: "patientViewDiabete", params: to.params };
          }

          return { name: "patientViewRespiratoire", params: to.params };
        }
      },
      {
        path: "info",
        alias: "informations",
        name: "patientViewInformations",
        meta: {
          title: localize(localPath, `title--patientView`),
          rights: [ACCESS_PATIENT]
        },
        component: () => {
          const component = componentNameResolver("PatientViewInformations", settings.default.overridedModules);
          return import(`@/modules/patient/view/_components/informations/${component}`);
        }
      },
      {
        path: "communication",
        alias: "communication",
        name: "patientViewCommunication",
        meta: {
          title: localize(localPath, `title--patientView`),
          rights: [ACCESS_PATIENT]
        },
        component: () => {
          const component = componentNameResolver("TabsPatientViewCommunication", settings.default.overridedModules);
          return import(`@/modules/patient/view/_components/communication/${component}`);
        }
      },
      {
        path: "questionnaires",
        alias: "questionnaires",
        name: "patientViewQuestionnaires",
        meta: {
          title: localize(localPath, `title--patientView`),
          rights: [ACCESS_PATIENT]
        },
        component: () => {
          const component = componentNameResolver("TabsPatientViewQuestionnaires", settings.default.overridedModules);
          return import(`@/modules/patient/view/_components/questionnaires/${component}`);
        }
      },
      {
        path: "info/edit",
        alias: "informations/edition",
        name: "patientEditInformations",
        meta: {
          title: localize(localPath, `title--patientEditInformations`),
          rights: [EDIT_PATIENT]
        },
        component: () => {
          const component = componentNameResolver("PatientEditInformations", settings.default.overridedModules);
          return import(`@/modules/patient/edit/_components/${component}`);
        }
      },
      {
        path: "reminders",
        alias: "reminders",
        name: "patientViewReminders",
        meta: {
          title: localize(localPath, "title--patientView"),
          rights: [ACCESS_PATIENT]
        },
        component: PatientViewReminders
      },
      {
        path: "monitoring",
        name: "patientViewMonitoring",
        meta: {
          title: localize(localPath, `title--patientView`),
          rights: [ACCESS_PATIENT, PATIENT_VIEW_MONITORING]
        },
        component: PatientViewMonitoring
      },
      {
        path: "respiratory",
        alias: "respiratoire",
        name: "patientViewRespiratoire",
        props: true,
        meta: {
          title: localize(localPath, `title--patientView`),
          rights: [ACCESS_PATIENT]
        },
        component: () => {
          const component = componentNameResolver("PatientViewRespiratory", settings.default.overridedModules);
          return import(`@/modules/patient/view/_components/${component}`);
        }
      },
      {
        path: "diabete",
        name: "patientViewDiabete",
        props: true,
        meta: {
          title: localize(localPath, `title--patientView`),
          rights: [ACCESS_PATIENT]
        },
        component: PatientViewDiabete
      },
      {
        path: "scheduling",
        alias: "planification",
        name: "patientPlanificationGeneral",
        meta: {
          title: localize(localPath, `title--patientPlanification`),
          rights: [ACCESS_PATIENT]
        },
        component: PatientPlanification
      },
      {
        path: "respiratory/scheduling",
        alias: "respiratoire/planification",
        name: "patientPlanificationRespiratoire",
        meta: {
          title: localize(localPath, `title--patientPlanification`),
          rights: [ACCESS_PATIENT]
        },
        component: PatientPlanification
      },
      {
        path: "diabete/scheduling",
        name: "patientPlanificationDiabete",
        meta: {
          title: localize(localPath, `title--patientPlanification`),
          rights: [ACCESS_PATIENT]
        },
        component: PatientPlanification
      },
      {
        path: "respiratory/edit/:contractId",
        alias: "respiratoire/edition/:contractId",
        props: true,
        name: "patientEditContractsRespiratoire",
        meta: {
          title: localize(localPath, `title--patientEditInformations`),
          rights: [EDIT_PATIENT]
        },
        component: () => {
          const component = componentNameResolver("PatientEditContract", settings.default.overridedModules);
          return import(`@/modules/patient/edit/_components/${component}`);
        }
      },
      {
        path: "diabete/edit/:contractId",
        props: true,
        name: "patientEditContractsDiabete",
        meta: {
          title: localize(localPath, `title--patientEditInformations`),
          rights: [EDIT_PATIENT]
        },
        component: PatientEditContractDiabete
      }
    ]
  }
];
