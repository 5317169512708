/* eslint-disable import/no-cycle */
import { fetchInterventions, searchPrescribers, searchPatients } from "@/modules/common/_api";

import * as api from "../_api";

/* eslint-disable no-shadow */

const state = {
  interventions: [],
  currentDivisions: ["R"],
  currentCount: 0,
  count: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  allInterventions: state => state.interventions,
  currentDivisions: state => state.currentDivisions,
  currentCount: state => state.currentCount,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_INTERVENTIONS: (state, interventions) => {
    state.interventions = interventions;
  },
  SET_CURRENT_DIVISIONS: (state, divisions) => {
    state.currentDivisions = divisions;
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  exportInterventions(context, data) {
    const { formattedQuery, currentDivisions } = context.state;
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    const { sort, currentCount } = data;
    return api.exportInterventions({
      sort,
      query: {
        ...formattedQuery,
        divisions: currentDivisions,
        user: {
          email,
          firstname,
          lastname,
          _id
        },
        currentCount
      }
    });
  },
  fetchInterventions(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId, currentDivisions } = context.state;
      fetchInterventions({ ...params, query: { ...formattedQuery, divisions: currentDivisions, withDeliveries: true } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_INTERVENTIONS", results);
          context.commit("SET_CURRENT_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { patients, prescribers, locationCodes, plannedDate, dateTime, installDate, consultationDate, cities, ...formattedQuery } = query;
    if (prescribers && prescribers.length) {
      formattedQuery.prescriptionAddressIds = prescribers.flatMap(item => item.prescriptionAddressIds);
    }
    if (patients && patients.length) {
      formattedQuery.patientIds = patients.map(item => item._id);
    }
    if (locationCodes && locationCodes.length) {
      formattedQuery.locationCodes = locationCodes.map(item => item.zipCode || item.departmentCode);
    }
    if (cities && cities.length) {
      formattedQuery.cities = cities.map(item => item.name || item.slug);
    }
    if (plannedDate) {
      formattedQuery.plannedDateStart = plannedDate.start || undefined;
      formattedQuery.plannedDateEnd = plannedDate.end || undefined;
    }
    if (dateTime) {
      formattedQuery.dateTimeStart = dateTime.start || undefined;
      formattedQuery.dateTimeEnd = dateTime.end || undefined;
    }
    if (installDate) {
      formattedQuery.installDateStart = installDate.start || undefined;
      formattedQuery.installDateEnd = installDate.end || undefined;
    }
    if (consultationDate) {
      formattedQuery.consultationDateStart = consultationDate.start || undefined;
      formattedQuery.consultationDateEnd = consultationDate.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  setCurrentDivisions(context, divisions) {
    context.commit("SET_CURRENT_DIVISIONS", divisions);
  },
  getInterventionsCount(context, params) {
    return new Promise((resolve, reject) => {
      api
        .getInterventionsCount(params)
        .then(res => {
          context.commit("SET_COUNT", { division: params.query.divisionId.toLowerCase(), count: res.data.body.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchPrescribers(context, input) {
    return searchPrescribers({
      query: { divisions: context.state.currentDivisions.code, input, activeStatus: ["active"] },
      skip: 0,
      limit: 10,
      source: "local"
    });
  },
  searchPatients(context, input) {
    return new Promise((resolve, reject) => {
      searchPatients({ query: { divisions: context.state.currentDivisions, input }, skip: 0, limit: 10, source: "local" })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
