const { contactTypesRespiratory, contactTypesDiabete, contactTypesRespiratoryMedpro } = require("@common/constants/contactTypes");

const getContactTypeRespiratory = typeId => {
  return contactTypesRespiratory.find(type => type.value === typeId);
};

const getContactTypeDiabete = typeId => {
  return contactTypesDiabete.find(type => type.value === typeId);
};

const getContactTypeRespiratoryMedpro = typeId => {
  return contactTypesRespiratoryMedpro.find(type => type.value === typeId);
};

module.exports = { getContactTypeRespiratory, getContactTypeDiabete, getContactTypeRespiratoryMedpro };
