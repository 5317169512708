/* eslint-disable no-shadow */
import { fetchAvailableUserExternalIds } from "@/modules/common/_api";
import * as api from "../_api";

const state = {
  availableUserExternalIds: []
};

const getters = {
  availableUserExternalIds: state => state.availableUserExternalIds
};

const mutations = {
  SET_AVAILABLE_USER_EXTERNAL_IDS: (state, availableUserExternalIds) => {
    state.availableUserExternalIds = availableUserExternalIds;
  }
};

const actions = {
  addUser(context, data) {
    return new Promise((resolve, reject) => {
      const { email, ...user } = data;

      api
        .addUser({ ...user, email: email.toLowerCase(), active: true })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkUserEmailAvailability(context, params) {
    return api.checkUserEmailAvailability(params);
  },
  fetchAvailableUserExternalIds(context) {
    return new Promise((resolve, reject) => {
      fetchAvailableUserExternalIds()
        .then(result => {
          const userExternalIds = result.data.body
            ? result.data.body.userExternalIds.map(externalId => ({ label: externalId, value: externalId }))
            : [];
          context.commit("SET_AVAILABLE_USER_EXTERNAL_IDS", userExternalIds);
          resolve();
        })
        .catch(err => reject(err));
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
