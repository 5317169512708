import http from "@/services/http";

export const fetchIntervention = id => http.get(`/interventions/${id}`);

export const editIntervention = (id, data) => http.put(`/interventions/${id}`, data);

export const editInterventionOffline = (id, data) => http.put(`/interventions/${id}/offline`, data);

export const editPatientContract = (id, data) => http.put(`/contracts/${id}`, data);

export const prepareIntervention = id => http.put(`/interventions/prepare/${id}`);

export const achieveIntervention = (id, data) => http.put(`/interventions/achieve/${id}`, data);

export const validateIntervention = id => http.put(`/interventions/validate/${id}`);

export const deleteIntervention = id => http.put(`/interventions/delete/${id}`);

export const createDelivery = delivery => http.post("/deliveries", delivery);

export const updateDelivery = (id, delivery) => http.put(`/deliveries/${id}`, delivery);

export const updateLegacyConsommables = (id, delivery) => http.put(`/legacy-consommables/${id}`, delivery);

export const archiveDelivery = id => http.put(`/deliveries/archive/${id}`);

export const fetchDeviceMetrics = () => http.get("/device-metrics", { useCache: true });

export const fetchDeviceSettings = () => http.get("/device-settings", { useCache: true });

export const sendOrder = deliveryInterventionId => http.get(`/deliveries/order/${deliveryInterventionId}`);

export const createOrders = data => http.post("/deliveries/create-orders", data);

export const generateInterventionReport = interventionId => http.get(`/interventions/generate-report/${interventionId}`);

export const updateAnomalyStatus = (interventionId, data) => http.put(`/interventions/${interventionId}/anomaly`, data);

export const signBl = (id, data) => http.put(`/interventions/${id}/sign-bl`, data);

export const syncAnomalyMachinesStock = interventionId => http.post(`/deliveries/sync-anomaly-stock/machines`, { interventionId });

export const syncAnomalyAgaProductsStock = interventionId => http.post(`/deliveries/sync-anomaly-stock/aga-products`, { interventionId });

export const updateByPdf = (interventionId, data) => http.put(`/interventions/${interventionId}/update-by-pdf`, data);

export const deleteInterventionAndReplanned = interventionId => http.put(`/interventions/${interventionId}/deleteAndReplanned`);

export const cancelDeliveryRemoval = deliveryId => http.put(`/deliveries/cancel-removal/${deliveryId}`);

export const updateInterventionQuestionnaire = data => http.post(`/interventions/questionnaire/`, data, { timeout: 600000 });

export const fetchInterventionQuestionnaire = id => http.get(`/interventions/${id}/questionnaire`);

export const fetchPatientLastInterventionQuestionnaire = patientId => http.get(`/patients/${patientId}/intervention-questionnaire/last`);
