/* eslint-disable no-shadow */
import { fetchStockContent, fetchDeliveries } from "@/modules/common/_api";
import { exportStock } from "@/modules/products/stock/_api";
import searchThroughTaxonomy from "@/helpers/_functions/searchThroughTaxonomy";

const deliveryStatuses = require("@common/constants/deliveryStatuses");

const state = {
  communityStock: null,
  stockTaxonomie: null,
  lastRequestId: null
};

const getters = {
  communityStock: state => state.communityStock
};

const mutations = {
  SET_COMMUNITY_STOCK: (state, communityStock) => {
    state.communityStock = communityStock;
  },
  SET_STOCK_TAXONOMIE: (state, stockTaxonomie) => {
    state.stockTaxonomie = stockTaxonomie;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  searchProducts(context, query) {
    const { stockTaxonomie } = context.state;
    return new Promise(resolve => {
      resolve(searchThroughTaxonomy(stockTaxonomie, query));
    });
  },
  updateStockTaxonomie(context) {
    const { communityStock } = context.state;
    const stockTaxonomie = communityStock ? communityStock.map(item => ({ ...item.product, value: item.productId })) : null;
    context.commit("SET_STOCK_TAXONOMIE", stockTaxonomie);
  },
  getStockContent(context, params) {
    return new Promise((resolve, reject) => {
      const { community } = context.state;
      fetchStockContent(community.stock._id, params)
        .then(res => resolve(res?.data?.body || []))
        .catch(err => reject(err));
    });
  },
  fetchCommunityStock(context, { requestId, ...params }) {
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { community } = context.state;
      if (!community.stock || !community.stock._id) {
        return resolve(null);
      }
      context
        .dispatch("getStockContent", params)
        .then(stock => {
          const { lastRequestId } = context.state;
          if (lastRequestId !== requestId) {
            return resolve();
          }
          context.commit("SET_COMMUNITY_STOCK", stock);
          return resolve();
        })
        .catch(err => reject(err));
    });
  },
  exportCommunityStock(context, params) {
    return exportStock(context.state.community.stock._id, params);
  },
  searchProductAndPatientLinkedBySerialNumber(context, input) {
    return new Promise((resolve, reject) => {
      const emptyResult = { data: { body: [] } };

      const deliveriesQuery = {
        query: {
          serialNumber: input,
          statusIds: [deliveryStatuses.waiting, deliveryStatuses.delivered]
        }
      };

      fetchDeliveries(deliveriesQuery)
        .then(deliveries => {
          if (!deliveries?.data?.body?.results?.length) {
            resolve(emptyResult);
            return;
          }

          const [delivery] = deliveries.data.body.results;

          if (!delivery.product) {
            resolve(emptyResult);
            return;
          }

          const patient = context.state.patients.find(patient => patient.contracts.some(contract => contract.id === delivery.contractId));

          if (!patient) {
            resolve(emptyResult);
            return;
          }

          resolve({
            data: {
              body: [
                {
                  product: { ...delivery.product, serialNumber: input },
                  patient: { ...patient, contractId: delivery.contractId }
                }
              ]
            }
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setCommunityStockTaxonomies(context, stock) {
    context.commit("SET_STOCK_TAXONOMIE", stock?.map(item => ({ ...item.product, value: item.productId })) || null);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
