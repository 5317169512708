import { addMaintenance, fetchMaintenance } from "../_api";

const state = {
  maintenance: {}
};

const getters = {
  maintenance: state => state.maintenance
};

const mutations = {
  SET_MAINTENANCE: (state, maintenance) => {
    state.maintenance = maintenance;
  }
};

const actions = {
  addMaintenance(context, data) {
    return new Promise((resolve, reject) => {
      addMaintenance(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchMaintenance(context, params) {
    return new Promise((resolve, reject) => {
      fetchMaintenance(params)
        .then(res => {
          context.commit("SET_MAINTENANCE", res.data?.body || {});
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
